define("ember-svg-jar/inlined/shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Service-1</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#203151\" width=\"48\" height=\"48\" rx=\"24\"/><path d=\"M13.92 12.96v10.56c0 4.44 5.22 11.52 10.08 11.52s10.08-7.08 10.08-11.52V12.96H13.92zM24 32.16c-3.407 0-7.2-4.864-7.2-8.16v-7.68a.48.48 0 01.48-.48h4.8a.48.48 0 110 .96h-4.32V24c0 2.778 3.407 7.2 6.24 7.2a.48.48 0 110 .96z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});