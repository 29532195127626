define("@clark/cms-ui/components/numbered-list/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NumberedListLayout = void 0;
  var NumberedListLayout;
  _exports.NumberedListLayout = NumberedListLayout;

  (function (NumberedListLayout) {
    NumberedListLayout["Rows"] = "rows";
    NumberedListLayout["Tiles"] = "tiles";
    NumberedListLayout["DesktopTiles"] = "desktop-tiles";
  })(NumberedListLayout || (_exports.NumberedListLayout = NumberedListLayout = {}));
});