define("@clark/cms-ui/components/spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vv2DKP44",
    "block": "{\"symbols\":[\"@accessibilityLabel\",\"&attrs\"],\"statements\":[[1,[28,\"spinner/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[7,\"div\",false],[13,2],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"src\",\"/assets/svgs/spinner.svg\"],[10,\"alt\",\"\"],[10,\"draggable\",\"false\"],[11,\"class\",[23,0,[\"classes\"]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[5,\"visually-hidden\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/spinner/template.hbs"
    }
  });

  _exports.default = _default;
});