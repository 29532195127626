define("@clark/cms-ui/components/textbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VDXGPUL0",
    "block": "{\"symbols\":[\"section\",\"item\",\"index\",\"@footer\",\"&attrs\",\"@header\",\"@items\"],\"statements\":[[1,[28,\"textbox/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\"],[7,\"div\",false],[13,5],[8],[0,\"\\n  \"],[5,\"common/cms-section\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"header\"]],[],[[\"@title\"],[[23,6,[\"title\"]]]]],[0,\"\\n    \"],[6,[23,1,[\"body\"]],[[12,\"class\",[29,[[28,\"grid\",[\"row\"],null],\" \",[28,\"local-class\",[\"textbox-list\"],[[\"from\"],[\"@clark/cms-ui/components/textbox/styles\"]]]]]],[12,\"data-test-textbox-items\",\"\"]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,7,[]]],null,{\"statements\":[[0,\"        \"],[5,\"textbox/textbox-item\",[[12,\"class\",[29,[[28,\"grid\",[[28,\"if\",[[28,\"eq\",[[28,\"mod\",[[23,3,[]],2],null],0],null],\"col-12 col-md-5\",\"col-12 offset-md-1 col-md-5\"],null]],null],\" \",[28,\"local-class\",[\"textbox-list-item\"],[[\"from\"],[\"@clark/cms-ui/components/textbox/styles\"]]]]]]],[[\"@icon\",\"@title\",\"@description\"],[[23,2,[\"icon\"]],[23,2,[\"title\"]],[23,2,[\"description\"]]]]],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,4,[\"mobile\",\"text\"]]],null,{\"statements\":[[0,\"        \"],[5,\"button\",[],[[\"@url\"],[[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,4,[\"mobile\",\"href\"]],[23,4,[\"desktop\",\"href\"]]],null]]],{\"statements\":[[0,\"\\n          \"],[1,[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,4,[\"mobile\",\"text\"]],[23,4,[\"desktop\",\"text\"]]],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/textbox/template.hbs"
    }
  });

  _exports.default = _default;
});