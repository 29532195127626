define("@clark/cms-ui/components/responsive-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3VWEjCXP",
    "block": "{\"symbols\":[\"@defaultImage\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"responsive-image\"],[[\"from\"],[\"@clark/cms-ui/components/responsive-image/styles\"]]]]]],[10,\"data-test-responsive-image\",\"\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isBeforeDesktop\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"img\",false],[12,\"class\",[29,[[28,\"local-class\",[\"image-default\"],[[\"from\"],[\"@clark/cms-ui/components/responsive-image/styles\"]]]]]],[12,\"src\",[23,1,[\"url\"]]],[12,\"alt\",[23,1,[\"altText\"]]],[12,\"data-test-responsive-image-image-default\",\"\"],[3,\"style\",null,[[\"maxWidth\"],[[23,0,[\"defaultImageMaxWidth\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\",false],[12,\"class\",[29,[[28,\"local-class\",[\"image-desktop\"],[[\"from\"],[\"@clark/cms-ui/components/responsive-image/styles\"]]]]]],[12,\"src\",[23,0,[\"desktopImage\",\"url\"]]],[12,\"alt\",[23,0,[\"desktopImage\",\"altText\"]]],[12,\"data-test-responsive-image-image-desktop\",\"\"],[3,\"style\",null,[[\"maxWidth\"],[[23,0,[\"desktopImageMaxWidth\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/responsive-image/template.hbs"
    }
  });

  _exports.default = _default;
});