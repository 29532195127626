define("@clark/cms-ui/styles/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "brand-primary": "#2B6CDE",
    "brand-primary-alternative": "#3A6BC1",
    "brand-secondary": "#17203D",
    "brand-secondary-alternative": "#384255",
    "c-title": "rgba(32, 49, 81, 1)",
    "c-copy": "rgba(32, 49, 81, 0.7)",
    "cta-primary": "#2B6CDE",
    "cta-primary-hover": "#094FCB",
    "cta-primary-pressed": "#103C8A",
    "cta-primary-border": "transparent",
    "cta-secondary": "#FFFFFF",
    "cta-secondary-hover": "#094FCB",
    "cta-secondary-pressed": "#103C8A",
    "cta-secondary-border": "#DADDEC",
    "background-light-gray": "#F5F6FA",
    "background-light-blue": "#E9F0FB",
    "background-dark": "#203151",
    "c-white": "#FFFFFF",
    "c-black": "#000000",
    "c-grey-lightest": "#f6f6f6",
    "c-grey-light": "#d6d6d6",
    "c-grey": "#bbb",
    "c-grey-dark": "#787878",
    "c-grey-darkest": "#626262",
    "divider": "#DADDEC",
    "quick-selection-border": "#DADDEC",
    "faq-border": "#DADDEC",
    "carousel-btn-border": "#DADDEC",
    "icon-color": "#C3CBD9",
    "footer-link-hover": "#2B6CDE",
    "on-hover-card-box-shadow": "0 3px 10px 0px rgba(0,0,0,0.15)",
    "input-border-default": "#A8AAB2",
    "input-border-active": "#2B6CDE",
    "input-border-danger": "#D51E50",
    "input-checkbox-border": "#d6d6d6",
    "input-checked": "#2B6CDE",
    "seo-body-link": "#0439D7",
    "seo-highlight-link": "#87CDF9",
    "higlight-background": "#080F8C",
    "modal-overlay": "rgba(32, 49, 81, 0.5)",
    "danger-text": "#D51E50",
    "heimat-brand-primary": "#0439D8",
    "heimat-brand-primary-alt": "#87CDF9",
    "heimat-brand-primary-translucent": "rgba(4, 57, 216, 0.33)",
    "heimat-brand-secondary": "#080F8C",
    "heimat-background-light-gray": "#F3F3F3",
    "heimat-c-white": "#FFFFFF",
    "heimat-c-black": "#0E1621",
    "heimat-c-red": "#F26363",
    "heimat-c-teal": "#54EFD1",
    "heimat-c-white-opaque": "rgba(255,255,255,0.5)",
    "heimat-c-white-translucent": "rgba(255, 255, 255, 0.33)"
  };
  _exports.default = _default;
});