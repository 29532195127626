define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M0 0h20v19H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#203151\" width=\"48\" height=\"48\" rx=\"24\"/><g transform=\"translate(14 14)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M19.863 7.527l-4.858 4.308 1.293 6.673a.416.416 0 01-.6.445l-5.72-2.927-5.72 2.924a.415.415 0 01-.6-.447l1.33-6.663L.136 7.527a.412.412 0 01.214-.713L6.745 5.79 9.63.224a.417.417 0 01.74 0l2.885 5.566 6.395 1.024c.344.055.471.48.213.713\" fill=\"#FFF\" mask=\"url(#b)\"/></g></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});