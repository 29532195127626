define("@clark/cms-ui/components/common/flyout/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ze+SjA+8",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@duration\",\"@menu\"],\"statements\":[[5,\"animated-container\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"animated-if\",[[23,4,[\"open\"]]],[[\"use\",\"duration\"],[[23,0,[\"slide\"]],[28,\"or\",[[23,3,[]],200],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"menu\"],[[\"from\"],[\"@clark/cms-ui/components/common/flyout/menu/styles\"]]]]]],[13,1],[8],[0,\"\\n      \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/flyout/menu/template.hbs"
    }
  });

  _exports.default = _default;
});