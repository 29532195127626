define("@clark/cms-ui/styles/grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gutter-width": "12px",
    "gutter-width-mobile": "8px",
    "breakpoint-mobile": "0px",
    "breakpoint-medium": "769px",
    "breakpoint-desktop": "1200px",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "medium": "only screen and (min-width: 400px) and (max-width: 769px)",
    "from-medium": "only screen and (min-width: 769px)",
    "from-desktop": "only screen and (min-width: 1200px)",
    "container-fluid": "_container-fluid_17fzo8",
    "container": "_container_17fzo8",
    "row": "_row_17fzo8",
    "row-fill-left": "_row-fill-left_17fzo8",
    "row-fill-right": "_row-fill-right_17fzo8",
    "row-fill": "_row-fill_17fzo8",
    "row-md-fill-left": "_row-md-fill-left_17fzo8",
    "row-md-fill-right": "_row-md-fill-right_17fzo8",
    "row-md-fill": "_row-md-fill_17fzo8",
    "row-lg-fill-left": "_row-lg-fill-left_17fzo8",
    "row-lg-fill-right": "_row-lg-fill-right_17fzo8",
    "row-lg-fill": "_row-lg-fill_17fzo8",
    "no-gutters": "_no-gutters_17fzo8",
    "col": "_col_17fzo8",
    "col-1": "_col-1_17fzo8",
    "col-2": "_col-2_17fzo8",
    "col-3": "_col-3_17fzo8",
    "col-4": "_col-4_17fzo8",
    "col-5": "_col-5_17fzo8",
    "col-6": "_col-6_17fzo8",
    "col-7": "_col-7_17fzo8",
    "col-8": "_col-8_17fzo8",
    "col-9": "_col-9_17fzo8",
    "col-10": "_col-10_17fzo8",
    "col-11": "_col-11_17fzo8",
    "col-12": "_col-12_17fzo8",
    "col-auto": "_col-auto_17fzo8",
    "col-md-1": "_col-md-1_17fzo8",
    "col-md-2": "_col-md-2_17fzo8",
    "col-md-3": "_col-md-3_17fzo8",
    "col-md-4": "_col-md-4_17fzo8",
    "col-md-5": "_col-md-5_17fzo8",
    "col-md-6": "_col-md-6_17fzo8",
    "col-md-7": "_col-md-7_17fzo8",
    "col-md-8": "_col-md-8_17fzo8",
    "col-md-9": "_col-md-9_17fzo8",
    "col-md-10": "_col-md-10_17fzo8",
    "col-md-11": "_col-md-11_17fzo8",
    "col-md-12": "_col-md-12_17fzo8",
    "col-md": "_col-md_17fzo8",
    "col-md-auto": "_col-md-auto_17fzo8",
    "col-lg-1": "_col-lg-1_17fzo8",
    "col-lg-2": "_col-lg-2_17fzo8",
    "col-lg-3": "_col-lg-3_17fzo8",
    "col-lg-4": "_col-lg-4_17fzo8",
    "col-lg-5": "_col-lg-5_17fzo8",
    "col-lg-6": "_col-lg-6_17fzo8",
    "col-lg-7": "_col-lg-7_17fzo8",
    "col-lg-8": "_col-lg-8_17fzo8",
    "col-lg-9": "_col-lg-9_17fzo8",
    "col-lg-10": "_col-lg-10_17fzo8",
    "col-lg-11": "_col-lg-11_17fzo8",
    "col-lg-12": "_col-lg-12_17fzo8",
    "col-lg": "_col-lg_17fzo8",
    "col-lg-auto": "_col-lg-auto_17fzo8",
    "order-first": "_order-first_17fzo8",
    "order-last": "_order-last_17fzo8",
    "order-0": "_order-0_17fzo8",
    "order-1": "_order-1_17fzo8",
    "order-2": "_order-2_17fzo8",
    "order-3": "_order-3_17fzo8",
    "order-4": "_order-4_17fzo8",
    "order-5": "_order-5_17fzo8",
    "order-6": "_order-6_17fzo8",
    "order-7": "_order-7_17fzo8",
    "order-8": "_order-8_17fzo8",
    "order-9": "_order-9_17fzo8",
    "order-10": "_order-10_17fzo8",
    "order-11": "_order-11_17fzo8",
    "order-12": "_order-12_17fzo8",
    "offset-1": "_offset-1_17fzo8",
    "offset-2": "_offset-2_17fzo8",
    "offset-3": "_offset-3_17fzo8",
    "offset-4": "_offset-4_17fzo8",
    "offset-5": "_offset-5_17fzo8",
    "offset-6": "_offset-6_17fzo8",
    "offset-7": "_offset-7_17fzo8",
    "offset-8": "_offset-8_17fzo8",
    "offset-9": "_offset-9_17fzo8",
    "offset-10": "_offset-10_17fzo8",
    "offset-11": "_offset-11_17fzo8",
    "order-md-first": "_order-md-first_17fzo8",
    "order-md-last": "_order-md-last_17fzo8",
    "order-md-0": "_order-md-0_17fzo8",
    "order-md-1": "_order-md-1_17fzo8",
    "order-md-2": "_order-md-2_17fzo8",
    "order-md-3": "_order-md-3_17fzo8",
    "order-md-4": "_order-md-4_17fzo8",
    "order-md-5": "_order-md-5_17fzo8",
    "order-md-6": "_order-md-6_17fzo8",
    "order-md-7": "_order-md-7_17fzo8",
    "order-md-8": "_order-md-8_17fzo8",
    "order-md-9": "_order-md-9_17fzo8",
    "order-md-10": "_order-md-10_17fzo8",
    "order-md-11": "_order-md-11_17fzo8",
    "order-md-12": "_order-md-12_17fzo8",
    "offset-md-0": "_offset-md-0_17fzo8",
    "offset-md-1": "_offset-md-1_17fzo8",
    "offset-md-2": "_offset-md-2_17fzo8",
    "offset-md-3": "_offset-md-3_17fzo8",
    "offset-md-4": "_offset-md-4_17fzo8",
    "offset-md-5": "_offset-md-5_17fzo8",
    "offset-md-6": "_offset-md-6_17fzo8",
    "offset-md-7": "_offset-md-7_17fzo8",
    "offset-md-8": "_offset-md-8_17fzo8",
    "offset-md-9": "_offset-md-9_17fzo8",
    "offset-md-10": "_offset-md-10_17fzo8",
    "offset-md-11": "_offset-md-11_17fzo8",
    "order-lg-first": "_order-lg-first_17fzo8",
    "order-lg-last": "_order-lg-last_17fzo8",
    "order-lg-0": "_order-lg-0_17fzo8",
    "order-lg-1": "_order-lg-1_17fzo8",
    "order-lg-2": "_order-lg-2_17fzo8",
    "order-lg-3": "_order-lg-3_17fzo8",
    "order-lg-4": "_order-lg-4_17fzo8",
    "order-lg-5": "_order-lg-5_17fzo8",
    "order-lg-6": "_order-lg-6_17fzo8",
    "order-lg-7": "_order-lg-7_17fzo8",
    "order-lg-8": "_order-lg-8_17fzo8",
    "order-lg-9": "_order-lg-9_17fzo8",
    "order-lg-10": "_order-lg-10_17fzo8",
    "order-lg-11": "_order-lg-11_17fzo8",
    "order-lg-12": "_order-lg-12_17fzo8",
    "offset-lg-0": "_offset-lg-0_17fzo8",
    "offset-lg-1": "_offset-lg-1_17fzo8",
    "offset-lg-2": "_offset-lg-2_17fzo8",
    "offset-lg-3": "_offset-lg-3_17fzo8",
    "offset-lg-4": "_offset-lg-4_17fzo8",
    "offset-lg-5": "_offset-lg-5_17fzo8",
    "offset-lg-6": "_offset-lg-6_17fzo8",
    "offset-lg-7": "_offset-lg-7_17fzo8",
    "offset-lg-8": "_offset-lg-8_17fzo8",
    "offset-lg-9": "_offset-lg-9_17fzo8",
    "offset-lg-10": "_offset-lg-10_17fzo8",
    "offset-lg-11": "_offset-lg-11_17fzo8",
    "flex-row": "_flex-row_17fzo8",
    "flex-column": "_flex-column_17fzo8",
    "flex-row-reverse": "_flex-row-reverse_17fzo8",
    "flex-column-reverse": "_flex-column-reverse_17fzo8",
    "flex-wrap": "_flex-wrap_17fzo8",
    "flex-nowrap": "_flex-nowrap_17fzo8",
    "flex-wrap-reverse": "_flex-wrap-reverse_17fzo8",
    "flex-fill": "_flex-fill_17fzo8",
    "flex-grow-0": "_flex-grow-0_17fzo8",
    "flex-grow-1": "_flex-grow-1_17fzo8",
    "flex-shrink-0": "_flex-shrink-0_17fzo8",
    "flex-shrink-1": "_flex-shrink-1_17fzo8",
    "justify-content-start": "_justify-content-start_17fzo8",
    "justify-content-end": "_justify-content-end_17fzo8",
    "justify-content-center": "_justify-content-center_17fzo8",
    "justify-content-between": "_justify-content-between_17fzo8",
    "justify-content-around": "_justify-content-around_17fzo8",
    "align-items-start": "_align-items-start_17fzo8",
    "align-items-end": "_align-items-end_17fzo8",
    "align-items-center": "_align-items-center_17fzo8",
    "align-items-baseline": "_align-items-baseline_17fzo8",
    "align-items-stretch": "_align-items-stretch_17fzo8",
    "align-content-start": "_align-content-start_17fzo8",
    "align-content-end": "_align-content-end_17fzo8",
    "align-content-center": "_align-content-center_17fzo8",
    "align-content-between": "_align-content-between_17fzo8",
    "align-content-around": "_align-content-around_17fzo8",
    "align-content-stretch": "_align-content-stretch_17fzo8",
    "align-self-auto": "_align-self-auto_17fzo8",
    "align-self-start": "_align-self-start_17fzo8",
    "align-self-end": "_align-self-end_17fzo8",
    "align-self-center": "_align-self-center_17fzo8",
    "align-self-baseline": "_align-self-baseline_17fzo8",
    "align-self-stretch": "_align-self-stretch_17fzo8",
    "flex-md-row": "_flex-md-row_17fzo8",
    "flex-md-column": "_flex-md-column_17fzo8",
    "flex-md-row-reverse": "_flex-md-row-reverse_17fzo8",
    "flex-md-column-reverse": "_flex-md-column-reverse_17fzo8",
    "flex-md-wrap": "_flex-md-wrap_17fzo8",
    "flex-md-nowrap": "_flex-md-nowrap_17fzo8",
    "flex-md-wrap-reverse": "_flex-md-wrap-reverse_17fzo8",
    "flex-md-fill": "_flex-md-fill_17fzo8",
    "flex-md-grow-0": "_flex-md-grow-0_17fzo8",
    "flex-md-grow-1": "_flex-md-grow-1_17fzo8",
    "flex-md-shrink-0": "_flex-md-shrink-0_17fzo8",
    "flex-md-shrink-1": "_flex-md-shrink-1_17fzo8",
    "justify-content-md-start": "_justify-content-md-start_17fzo8",
    "justify-content-md-end": "_justify-content-md-end_17fzo8",
    "justify-content-md-center": "_justify-content-md-center_17fzo8",
    "justify-content-md-between": "_justify-content-md-between_17fzo8",
    "justify-content-md-around": "_justify-content-md-around_17fzo8",
    "align-items-md-start": "_align-items-md-start_17fzo8",
    "align-items-md-end": "_align-items-md-end_17fzo8",
    "align-items-md-center": "_align-items-md-center_17fzo8",
    "align-items-md-baseline": "_align-items-md-baseline_17fzo8",
    "align-items-md-stretch": "_align-items-md-stretch_17fzo8",
    "align-content-md-start": "_align-content-md-start_17fzo8",
    "align-content-md-end": "_align-content-md-end_17fzo8",
    "align-content-md-center": "_align-content-md-center_17fzo8",
    "align-content-md-between": "_align-content-md-between_17fzo8",
    "align-content-md-around": "_align-content-md-around_17fzo8",
    "align-content-md-stretch": "_align-content-md-stretch_17fzo8",
    "align-self-md-auto": "_align-self-md-auto_17fzo8",
    "align-self-md-start": "_align-self-md-start_17fzo8",
    "align-self-md-end": "_align-self-md-end_17fzo8",
    "align-self-md-center": "_align-self-md-center_17fzo8",
    "align-self-md-baseline": "_align-self-md-baseline_17fzo8",
    "align-self-md-stretch": "_align-self-md-stretch_17fzo8",
    "flex-lg-row": "_flex-lg-row_17fzo8",
    "flex-lg-column": "_flex-lg-column_17fzo8",
    "flex-lg-row-reverse": "_flex-lg-row-reverse_17fzo8",
    "flex-lg-column-reverse": "_flex-lg-column-reverse_17fzo8",
    "flex-lg-wrap": "_flex-lg-wrap_17fzo8",
    "flex-lg-nowrap": "_flex-lg-nowrap_17fzo8",
    "flex-lg-wrap-reverse": "_flex-lg-wrap-reverse_17fzo8",
    "flex-lg-fill": "_flex-lg-fill_17fzo8",
    "flex-lg-grow-0": "_flex-lg-grow-0_17fzo8",
    "flex-lg-grow-1": "_flex-lg-grow-1_17fzo8",
    "flex-lg-shrink-0": "_flex-lg-shrink-0_17fzo8",
    "flex-lg-shrink-1": "_flex-lg-shrink-1_17fzo8",
    "justify-content-lg-start": "_justify-content-lg-start_17fzo8",
    "justify-content-lg-end": "_justify-content-lg-end_17fzo8",
    "justify-content-lg-center": "_justify-content-lg-center_17fzo8",
    "justify-content-lg-between": "_justify-content-lg-between_17fzo8",
    "justify-content-lg-around": "_justify-content-lg-around_17fzo8",
    "align-items-lg-start": "_align-items-lg-start_17fzo8",
    "align-items-lg-end": "_align-items-lg-end_17fzo8",
    "align-items-lg-center": "_align-items-lg-center_17fzo8",
    "align-items-lg-baseline": "_align-items-lg-baseline_17fzo8",
    "align-items-lg-stretch": "_align-items-lg-stretch_17fzo8",
    "align-content-lg-start": "_align-content-lg-start_17fzo8",
    "align-content-lg-end": "_align-content-lg-end_17fzo8",
    "align-content-lg-center": "_align-content-lg-center_17fzo8",
    "align-content-lg-between": "_align-content-lg-between_17fzo8",
    "align-content-lg-around": "_align-content-lg-around_17fzo8",
    "align-content-lg-stretch": "_align-content-lg-stretch_17fzo8",
    "align-self-lg-auto": "_align-self-lg-auto_17fzo8",
    "align-self-lg-start": "_align-self-lg-start_17fzo8",
    "align-self-lg-end": "_align-self-lg-end_17fzo8",
    "align-self-lg-center": "_align-self-lg-center_17fzo8",
    "align-self-lg-baseline": "_align-self-lg-baseline_17fzo8",
    "align-self-lg-stretch": "_align-self-lg-stretch_17fzo8",
    "d-none": "_d-none_17fzo8",
    "d-inline": "_d-inline_17fzo8",
    "d-inline-block": "_d-inline-block_17fzo8",
    "d-block": "_d-block_17fzo8",
    "d-table": "_d-table_17fzo8",
    "d-table-row": "_d-table-row_17fzo8",
    "d-table-cell": "_d-table-cell_17fzo8",
    "d-flex": "_d-flex_17fzo8",
    "d-inline-flex": "_d-inline-flex_17fzo8",
    "d-md-none": "_d-md-none_17fzo8",
    "d-md-inline": "_d-md-inline_17fzo8",
    "d-md-inline-block": "_d-md-inline-block_17fzo8",
    "d-md-block": "_d-md-block_17fzo8",
    "d-md-table": "_d-md-table_17fzo8",
    "d-md-table-row": "_d-md-table-row_17fzo8",
    "d-md-table-cell": "_d-md-table-cell_17fzo8",
    "d-md-flex": "_d-md-flex_17fzo8",
    "d-md-inline-flex": "_d-md-inline-flex_17fzo8",
    "d-lg-none": "_d-lg-none_17fzo8",
    "d-lg-inline": "_d-lg-inline_17fzo8",
    "d-lg-inline-block": "_d-lg-inline-block_17fzo8",
    "d-lg-block": "_d-lg-block_17fzo8",
    "d-lg-table": "_d-lg-table_17fzo8",
    "d-lg-table-row": "_d-lg-table-row_17fzo8",
    "d-lg-table-cell": "_d-lg-table-cell_17fzo8",
    "d-lg-flex": "_d-lg-flex_17fzo8",
    "d-lg-inline-flex": "_d-lg-inline-flex_17fzo8",
    "w-100": "_w-100_17fzo8"
  };
  _exports.default = _default;
});