define("@clark/cms-ui/components/heimat/cta-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pPXW0JnD",
    "block": "{\"symbols\":[\"@color\",\"@content\"],\"statements\":[[7,\"section\",true],[11,\"class\",[29,[[28,\"typo\",[\"heimat-sans\"],null],\" \",[28,\"local-class\",[[28,\"concat\",[\"heimat-cta-section \",[23,1,[]]],null]],[[\"from\"],[\"@clark/cms-ui/components/heimat/cta-section/styles\"]]]]]],[10,\"data-test-cta-section\",\"\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"grid\",[\"container\"],null],\" \",[28,\"local-class\",[\"heimat-cta-section-content\"],[[\"from\"],[\"@clark/cms-ui/components/heimat/cta-section/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"h2\",true],[11,\"class\",[29,[[28,\"typo\",[\"heimat-serif heimat-h2\"],null],\" \",[28,\"local-class\",[\"heimat-cta-section-title\"],[[\"from\"],[\"@clark/cms-ui/components/heimat/cta-section/styles\"]]]]]],[10,\"data-test-cta-section-title\",\"\"],[8],[1,[23,2,[\"fields\",\"title\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"heimat-cta-section-body\"],[[\"from\"],[\"@clark/cms-ui/components/heimat/cta-section/styles\"]]]]]],[10,\"data-test-cta-section-body\",\"\"],[8],[1,[23,2,[\"fields\",\"body\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"heimat-cta-section-button-container\"],[[\"from\"],[\"@clark/cms-ui/components/heimat/cta-section/styles\"]]]]]],[8],[0,\"\\n      \"],[5,\"heimat/input-button\",[[12,\"class\",[29,[[28,\"local-class\",[\"heimat-cta-section-button\"],[[\"from\"],[\"@clark/cms-ui/components/heimat/cta-section/styles\"]]]]]],[12,\"data-test-cta-section-button\",\"\"]],[[\"@style\",\"@href\"],[[28,\"if\",[[28,\"or\",[[28,\"is-empty\",[[23,1,[]]],null],[28,\"eq\",[[23,1,[]],\"color-blue\"],null]],null],\"heimat-white\",\"heimat-primary\"],null],[23,2,[\"fields\",\"ctaLink\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,2,[\"fields\",\"ctaText\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/heimat/cta-section/template.hbs"
    }
  });

  _exports.default = _default;
});