define("@clark/cms-ui/components/next-steps/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "background-light-gray": "#F5F6FA",
    "section-background": "#F5F6FA",
    "max-content-width": "calc(758px + 24px * 2)",
    "title-color": "#01051c",
    "next-steps": "_next-steps_1cuqa7",
    "layout-container": "_layout-container_1cuqa7",
    "title": "_title_1cuqa7"
  };
  _exports.default = _default;
});