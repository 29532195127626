define("ember-svg-jar/inlined/reise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.44 3.4c-.968.366-1.617 1.347-1.62 2.45v32.19c0 .66-.347 1.262-.895 1.556l-2.345 1.26v2.404h4.86V3.4zM27.3 18.115V26.034l3.24 1.566-1.62-8.7-1.62-.785z\" fill=\"#BFD2F5\"/><path d=\"M19.2 18.115V26.034L15.96 27.6l1.62-8.7 1.62-.785z\" fill=\"#BFD2F5\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M27.3 16.206l15.25 7.396c.754.369 1.128 1.29.866 2.134l-.749 2.415c-.239.773-.944 1.263-1.698 1.181l-3.949-.447v1.325c0 .48-.363.87-.81.87-.447 0-.81-.39-.81-.87v-1.509l-1.62-.184v1.693c0 .48-.363.87-.81.87-.447 0-.81-.39-.81-.87v-1.74a.877.877 0 01.025-.133l-4.885-.553V38.04l2.345 1.26c.548.294.895.896.895 1.555v2.405c0 .961-.725 1.74-1.62 1.74H17.58c-.895 0-1.62-.779-1.62-1.74v-2.405c0-.659.347-1.261.895-1.556L19.2 38.04V27.784l-4.885.553c.012.044.02.088.025.133v1.74c0 .48-.363.87-.81.87-.447 0-.81-.39-.81-.87v-1.693l-1.62.184v1.509c0 .48-.363.87-.81.87-.447 0-.81-.39-.81-.87v-1.325l-3.95.447c-.753.082-1.459-.409-1.697-1.18l-.75-2.416c-.262-.844.112-1.765.867-2.134l15.25-7.396V5.85c0-2.402 1.813-4.35 4.05-4.35s4.05 1.948 4.05 4.35v10.356zM4.617 25.188L5.37 27.6l13.83-1.566v-7.92L4.617 25.189zm21.958 14.408l2.345 1.26v2.404H17.58v-2.404l2.345-1.26c.549-.294.895-.897.895-1.556V5.85c0-1.441 1.088-2.61 2.43-2.61s2.43 1.169 2.43 2.61v32.19c0 .659.346 1.262.895 1.556zm.725-13.562l13.84 1.567.74-2.415-14.58-7.071v7.919z\" fill=\"#2B6CDE\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});