define("@clark/cms-ui/components/breadcrumbs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b6CGdVQi",
    "block": "{\"symbols\":[\"breadcrumb\",\"index\",\"isLinkless\",\"Element\"],\"statements\":[[4,\"if\",[[28,\"gt\",[[23,0,[\"breadcrumbs\",\"length\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"nav\",true],[11,\"aria-label\",[28,\"t\",[\"breadcrumbs.accessibility_label\"],null]],[10,\"data-test-breadcrumbs-nav\",\"\"],[8],[0,\"\\n    \"],[7,\"ol\",true],[11,\"class\",[29,[[28,\"local-class\",[\"breadcrumbs\"],[[\"from\"],[\"@clark/cms-ui/components/breadcrumbs/styles\"]]]]]],[10,\"data-test-breadcrumbs\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"breadcrumbs\"]]],null,{\"statements\":[[4,\"let\",[[28,\"is-empty\",[[23,1,[\"url\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"item-container \",[28,\"if\",[[23,3,[]],\"is-linkless\"],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/breadcrumbs/styles\"]]]]]],[10,\"data-test-breadcrumb-container\",\"\"],[11,\"data-test-breadcrumb-linkless\",[23,3,[]]],[8],[0,\"\\n\"],[4,\"let\",[[28,\"if\",[[23,3,[]],[28,\"component\",[[28,\"-element\",[\"span\"],null]],[[\"tagName\"],[\"span\"]]],[28,\"component\",[[28,\"-element\",[\"a\"],null]],[[\"tagName\"],[\"a\"]]]],null]],null,{\"statements\":[[0,\"              \"],[6,[23,4,[]],[[12,\"class\",[29,[[28,\"local-class\",[\"item\"],[[\"from\"],[\"@clark/cms-ui/components/breadcrumbs/styles\"]]]]]],[12,\"href\",[23,1,[\"url\"]]],[12,\"aria-current\",[28,\"if\",[[28,\"eq\",[[23,2,[]],[28,\"sub\",[[23,0,[\"breadcrumbs\",\"length\"]],1],null]],null],\"page\"],null]],[12,\"data-test-breadcrumb\",\"\"]],[[],[]],{\"statements\":[[0,\"\\n                \"],[1,[23,1,[\"label\"]],false],[0,\"\\n              \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/breadcrumbs/template.hbs"
    }
  });

  _exports.default = _default;
});