define("@clark/cms-ui/components/highlight-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "background-light-gray": "#F5F6FA",
    "seo-highlight-link": "#87CDF9",
    "section-background": "#F5F6FA",
    "max-content-width": "calc(758px + 24px * 2)",
    "title-color": "#01051c",
    "text-color": "#203151",
    "stripe-color": "#0439d7",
    "highlight-box": "_highlight-box_i47ydo",
    "layout-container": "_layout-container_i47ydo",
    "striped-content": "_striped-content_i47ydo",
    "stripe": "_stripe_i47ydo",
    "content": "_content_i47ydo",
    "title": "_title_i47ydo",
    "description": "_description_i47ydo"
  };
  _exports.default = _default;
});