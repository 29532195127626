define("@clark/cms-ui/components/video-player/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "heimat-c-black": "#0E1621",
    "heimat-c-white": "#FFFFFF",
    "from-medium": "only screen and (min-width: 769px)",
    "from-desktop": "only screen and (min-width: 1200px)",
    "border-radius-large": "0.5rem",
    "duration-normal": "200ms",
    "duration-slowest": "500ms",
    "video-player-root": "_video-player-root_o7jxsb",
    "box": "_box_o7jxsb",
    "is-initial": "_is-initial_o7jxsb",
    "has-interacted": "_has-interacted_o7jxsb",
    "controls-overlay": "_controls-overlay_o7jxsb",
    "is-playing": "_is-playing_o7jxsb",
    "play-button-container": "_play-button-container_o7jxsb",
    "preview-image": "_preview-image_o7jxsb",
    "video-embed": "_video-embed_o7jxsb",
    "is-hidden": "_is-hidden_o7jxsb"
  };
  _exports.default = _default;
});