define("@clark/cms-ui/components/numbered-list/item/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NumberedListItemOrientation = void 0;
  var NumberedListItemOrientation;
  _exports.NumberedListItemOrientation = NumberedListItemOrientation;

  (function (NumberedListItemOrientation) {
    NumberedListItemOrientation["Horizontal"] = "horizontal";
    NumberedListItemOrientation["Vertical"] = "vertical";
    NumberedListItemOrientation["DesktopVertical"] = "desktop-vertical";
  })(NumberedListItemOrientation || (_exports.NumberedListItemOrientation = NumberedListItemOrientation = {}));
});