define("@clark/cms-ui/components/faq/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xc9TBAjp",
    "block": "{\"symbols\":[\"section\",\"item\",\"@header\",\"@items\"],\"statements\":[[5,\"common/cms-section\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[\"@title\"],[[23,3,[\"title\"]]]]],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[[12,\"class\",[29,[[28,\"grid\",[\"row align-items-md-end\"],null],\" \",[28,\"local-class\",[\"faq-list\"],[[\"from\"],[\"@clark/cms-ui/components/faq/styles\"]]]]]]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"faq-list-item\"],[[\"from\"],[\"@clark/cms-ui/components/faq/styles\"]]]]]],[8],[0,\"\\n        \"],[5,\"faq/faq-item\",[],[[\"@title\",\"@description\"],[[23,2,[\"title\"]],[23,2,[\"description\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/faq/template.hbs"
    }
  });

  _exports.default = _default;
});