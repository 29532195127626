define("ember-animated/-private/margin-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.collapsedChildren = collapsedChildren;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // takes an element, the element's computed style, and either 'Top' or
  // 'Bottom'.
  //
  // returns list of Element for increasingly deep descendants
  // of the given element whose top (or bottom) margin collapses with
  // the given element's.
  function collapsedChildren(element, cs, which) {
    var children = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

    // margin collapse only happens if we have no border or padding
    if (isBlock(cs) && cs["border".concat(which, "Width")] === '0px' && cs["padding".concat(which)] === '0px') {
      var block;

      if (which === 'Top') {
        block = firstChildBlock(element);
      } else {
        block = lastChildBlock(element);
      }

      if (block) {
        var _block = block,
            _block2 = _slicedToArray(_block, 2),
            child = _block2[0],
            childCS = _block2[1];

        children.push(child);
        collapsedChildren(child, childCS, which, children);
      }
    }

    return children;
  }

  function firstChildBlock(element) {
    for (var i = 0; i < element.children.length; i++) {
      var child = element.children[i];
      var childCS = getComputedStyle(child);

      if (childCS.clear !== 'none') {
        // an intervening block with clearance prevents margin collapse
        return;
      }

      if (isBlock(childCS)) {
        return [child, childCS];
      }
    }
  }

  function lastChildBlock(element) {
    for (var i = element.children.length - 1; i >= 0; i--) {
      var child = element.children[i];
      var childCS = getComputedStyle(child);

      if (childCS.clear !== 'none') {
        // an intervening block with clearance prevents margin collapse
        return;
      }

      if (isBlock(childCS)) {
        return [child, childCS];
      }
    }
  }

  function isBlock(cs) {
    return cs.display === 'block' && (cs.position === 'static' || cs.position === 'relative') && cs.float === 'none' && cs.overflow === 'visible';
  }
});