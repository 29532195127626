define("ember-is-mobile/services/is-mobile", ["exports", "ember-is-mobile"], function (_exports, _emberIsMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The attributes returned by isMobile are accessible. However, they should be
   * accessed using the `get` helper, since they may be undefined if the user
   * agent header is blank.
   */
  var _default = Ember.Service.extend({
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var queries;

      if (Ember.get(this, 'fastboot.isFastBoot')) {
        var headers = Ember.get(this, 'fastboot.request.headers');
        var userAgent = Ember.get(headers, 'headers.user-agent'); // isMobile tries to fetch `navigator` if we give it a blank user agent.

        if (Ember.isBlank(userAgent)) {
          return;
        }

        userAgent = userAgent[0];
        queries = (0, _emberIsMobile.default)(userAgent);
      } else {
        queries = _emberIsMobile.default;
      }

      for (var media in queries) {
        Ember.set(this, media, queries[media]);
      }
    }
  });

  _exports.default = _default;
});