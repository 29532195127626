define("ember-svg-jar/inlined/user-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Service-4</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#203151\" width=\"48\" height=\"48\" rx=\"24\"/><path d=\"M16.073 28.738a.531.531 0 01-.006-.527c.586-1.055.996-4.663.996-8.773a7.438 7.438 0 0114.875 0c0 4.11.409 7.718.995 8.773a.531.531 0 01-.464.789H30.57c-.98-.569-2.005-1.137-2.848-1.542a8.456 8.456 0 002.036-4.868.528.528 0 00.115-1.05 4.984 4.984 0 01-4.065-3.329.52.52 0 00-.51-.367.531.531 0 00-.504.374 4.778 4.778 0 01-5.492 3.285.531.531 0 10-.21 1.041c.048.01.097.006.145.015a8.488 8.488 0 001.995 4.851 31.933 31.933 0 00-2.95 1.59H16.53a.531.531 0 01-.458-.262zm9.244-9.051a5.783 5.783 0 01-5.008 2.97c.33 2.88 2.176 5.317 4.191 5.317 2.086 0 3.993-2.61 4.222-5.62a6.088 6.088 0 01-3.405-2.667zm7.097 11.66c-1.731-1.072-3.037-1.823-4.011-2.347-.317-.17-.597-.316-.844-.44l-.08-.039a17.834 17.834 0 00-.618-.294c-.689.5-1.51.781-2.36.809a4.268 4.268 0 01-2.415-.846c-.194.08-.431.187-.696.314l-.03.014a24.84 24.84 0 00-.937.482c-.963.518-2.223 1.26-3.86 2.324a1.26 1.26 0 00.464 2.286c2.47.424 4.968.66 7.473.703a49.155 49.155 0 007.474-.703 1.233 1.233 0 00.44-2.263z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});