define("@clark/cms-ui/components/partner-header/nav-item/dropdown-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u8k1VjPH",
    "block": "{\"symbols\":[\"@position\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"dropdown-menu \",[28,\"if\",[[23,1,[]],[28,\"concat\",[\"menu-\",[23,1,[]]],null]],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/partner-header/nav-item/dropdown-menu/styles\"]]]]]],[13,2],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/partner-header/nav-item/dropdown-menu/template.hbs"
    }
  });

  _exports.default = _default;
});