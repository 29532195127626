define("@clark/cms-ui/components/picture-frame/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BYJbnfMz",
    "block": "{\"symbols\":[\"@imgSrc1\",\"@imgSrc2\",\"@imgSrc3\"],\"statements\":[[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"container\"],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"row justify-content-center\"],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"grid\",[\"col-md-10\"],null],\" \",[28,\"local-class\",[\"picture-frame-container\"],[[\"from\"],[\"@clark/cms-ui/components/picture-frame/styles\"]]]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"row\"],null]],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-6\"],null]],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"class\",[29,[[28,\"local-class\",[\"large-img\"],[[\"from\"],[\"@clark/cms-ui/components/picture-frame/styles\"]]]]]],[11,\"src\",[23,1,[\"url\"]]],[11,\"alt\",[23,1,[\"altText\"]]],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"grid\",[\"col-6 row no-gutters\"],null],\" \",[28,\"local-class\",[\"right-container\"],[[\"from\"],[\"@clark/cms-ui/components/picture-frame/styles\"]]]]]],[8],[0,\"\\n          \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-12\"],null]],[8],[0,\"\\n            \"],[7,\"img\",true],[11,\"src\",[23,2,[\"url\"]]],[11,\"alt\",[23,2,[\"altText\"]]],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-12\"],null]],[8],[0,\"\\n            \"],[7,\"img\",true],[11,\"src\",[23,3,[\"url\"]]],[11,\"alt\",[23,3,[\"altText\"]]],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/picture-frame/template.hbs"
    }
  });

  _exports.default = _default;
});