define("@clark/cms-ui/components/skeptics-slider/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UvJ9n2p+",
    "block": "{\"symbols\":[\"@skeptic\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"item\"],[[\"from\"],[\"@clark/cms-ui/components/skeptics-slider/image/styles\"]]]]]],[10,\"data-test-skeptics-slider-image\",\"\"],[8],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"bottom\"],[[\"from\"],[\"@clark/cms-ui/components/skeptics-slider/image/styles\"]]]]]],[11,\"style\",[23,0,[\"currentStyle\"]]],[8],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"top\"],[[\"from\"],[\"@clark/cms-ui/components/skeptics-slider/image/styles\"]]]]]],[12,\"style\",[23,0,[\"nextStyle\"]]],[3,\"did-update\",[[23,0,[\"didUpdate\"]],[23,1,[]]]],[3,\"on\",[\"animationend\",[23,0,[\"animationEnded\"]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/skeptics-slider/image/template.hbs"
    }
  });

  _exports.default = _default;
});