define("@clark/cms-ui/components/cms-footer/iconed-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4BhLrAo+",
    "block": "{\"symbols\":[\"&attrs\",\"@media\",\"&default\"],\"statements\":[[1,[28,\"iconed-content/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"container\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/iconed-content/styles\"]]]]]],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"media-container\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/iconed-content/styles\"]]]]]],[10,\"data-test-iconed-media\",\"\"],[8],[0,\"\\n    \"],[5,\"icon\",[],[[\"@source\"],[[23,2,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"content-container\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/iconed-content/styles\"]]]]]],[10,\"data-test-iconed-content\",\"\"],[8],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/cms-footer/iconed-content/template.hbs"
    }
  });

  _exports.default = _default;
});