define("@clark/cms-ui/components/centered-content/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "breakpoint-desktop": "1200px",
    "content-container": "_content-container_m6q27e",
    "left-content": "_left-content_m6q27e",
    "right-content": "_right-content_m6q27e",
    "center-content": "_center-content_m6q27e",
    "title": "_title_m6q27e"
  };
  _exports.default = _default;
});