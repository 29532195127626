define("@clark/cms-ui/components/heimat/cta-section/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "gutter-width": "12px",
    "heimat-background-light-gray": "#F3F3F3",
    "heimat-brand-primary": "#0439D8",
    "heimat-c-black": "#0E1621",
    "heimat-c-white": "#FFFFFF",
    "heimat-cta-section": "_heimat-cta-section_1o1mky",
    "heimat-cta-section-title": "_heimat-cta-section-title_1o1mky",
    "color-blue": "_color-blue_1o1mky _heimat-cta-section_1o1mky",
    "color-white": "_color-white_1o1mky",
    "color-grey": "_color-grey_1o1mky",
    "heimat-cta-section-content": "_heimat-cta-section-content_1o1mky",
    "heimat-cta-section-body": "_heimat-cta-section-body_1o1mky",
    "heimat-cta-section-button-container": "_heimat-cta-section-button-container_1o1mky",
    "heimat-cta-section-button": "_heimat-cta-section-button_1o1mky"
  };
  _exports.default = _default;
});