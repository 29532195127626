define("@clark/cms-ui/components/modal/close-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OYDRZNJ/",
    "block": "{\"symbols\":[\"classes\",\"@onClose\",\"@isHeimatRedesign\",\"@placement\"],\"statements\":[[4,\"let\",[[28,\"local-class\",[[28,\"join\",[\" \",[28,\"array\",[\"close-button\",[28,\"concat\",[\"placement-\",[28,\"or\",[[23,4,[]],\"modal\"],null]],null],[28,\"if\",[[23,3,[]],\"heimat\"],null]],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/modal/close-button/styles\"]]]],null,{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[5,\"control-button\",[[12,\"class\",[23,1,[]]]],[[\"@accessibilityLabel\",\"@icon\",\"@onClick\"],[[28,\"t\",[\"common.close\"],null],\"heimat-close\",[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",[23,1,[]]],[12,\"aria-label\",[28,\"t\",[\"common.close\"],null]],[3,\"on\",[\"click\",[23,2,[]]]],[8],[0,\"\\n      \"],[5,\"icon\",[],[[\"@source\"],[\"delete\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/close-button/template.hbs"
    }
  });

  _exports.default = _default;
});