define("@clark/cms-ui/components/modal/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vt0urXhe",
    "block": "{\"symbols\":[\"@isSubdued\",\"@flush\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[28,\"local-class\",[[28,\"join\",[\" \",[28,\"array\",[\"section\",[28,\"if\",[[23,2,[]],\"flush\"],null],[28,\"if\",[[23,1,[]],\"subdued\"],null]],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/modal/section/styles\"]]]],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/section/template.hbs"
    }
  });

  _exports.default = _default;
});