define("@clark/cms-ui/components/quick-selection/quick-selection-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oh7pi/Ix",
    "block": "{\"symbols\":[\"&attrs\",\"@icon\",\"@title\"],\"statements\":[[1,[28,\"quick-selection-item/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[7,\"button\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"quick-selection-item \",[28,\"if\",[[23,0,[\"isSelected\"]],\"quick-selection-item-selected\"],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/quick-selection/quick-selection-item/styles\"]]]]]],[13,1],[3,\"on\",[\"click\",[23,0,[\"highlightItem\"]]]],[8],[0,\"\\n  \"],[5,\"icon\",[[12,\"class\",[29,[[28,\"local-class\",[\"quick-selection-item-icon\"],[[\"from\"],[\"@clark/cms-ui/components/quick-selection/quick-selection-item/styles\"]]]]]]],[[\"@source\"],[[23,2,[]]]]],[0,\"\\n  \"],[7,\"h4\",true],[11,\"class\",[28,\"typo\",[\"h4\"],null]],[8],[0,\"\\n    \"],[1,[23,3,[]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"selected-sign\"],[[\"from\"],[\"@clark/cms-ui/components/quick-selection/quick-selection-item/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"icon\",[],[[\"@source\",\"@size\"],[\"check-ui\",\"large\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/quick-selection/quick-selection-item/template.hbs"
    }
  });

  _exports.default = _default;
});