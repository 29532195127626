define("ember-svg-jar/inlined/kfz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ico-kfz</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M37.882 28.103c.08.251.123.512.126.775a2.704 2.704 0 01-2.704 2.704 2.672 2.672 0 01-.775-.126 2.696 2.696 0 103.353-3.353zM14.45 28.103c.08.251.123.512.126.775a2.704 2.704 0 01-2.703 2.704 2.672 2.672 0 01-.775-.126 2.696 2.696 0 103.352-3.353zM5.564 27.977h5.407v1.802H5.564z\" fill=\"#BFD2F5\"/><path fill=\"#BFD2F5\" d=\"M16.38 27.977h18.023v1.802H16.38zM39.81 27.977h5.407v1.802H39.81zM14.576 13.558v5.407H6.838a9.267 9.267 0 017.738-5.407z\"/><path d=\"M46.992 28.496l-.698-4.889a5.407 5.407 0 00-5.353-4.642h-3.615a2.703 2.703 0 01-1.912-.792l-4.834-4.834a5.407 5.407 0 00-3.824-1.584h-12.18c-5.407 0-10.814 5.407-10.814 12.617v5.408H2.86a.901.901 0 100 1.802h6.4a4.506 4.506 0 008.831 0h14.601a4.506 4.506 0 008.83 0h2.795a2.704 2.704 0 002.675-3.086zM16.38 13.558h10.377a3.582 3.582 0 012.55 1.055l4.35 4.352H16.38v-5.407zm-1.803 0v5.407H6.838a9.267 9.267 0 017.738-5.407zm-.9 19.826a2.704 2.704 0 110-5.407 2.704 2.704 0 010 5.407zm23.43 0a2.704 2.704 0 110-5.407 2.704 2.704 0 010 5.407zm7.89-3.915a.89.89 0 01-.68.31h-2.793a4.506 4.506 0 00-8.83 0H18.09a4.506 4.506 0 00-8.83 0H5.564v-5.407c0-1.222.184-2.438.547-3.605h34.83a3.623 3.623 0 013.57 3.096l.698 4.888a.891.891 0 01-.212.718z\" fill=\"#2B6CDE\"/><path d=\"M26.292 24.372h-3.605a.901.901 0 100 1.803h3.605a.901.901 0 100-1.803z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});