define("@clark/cms-ui/components/backdrop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ATaFuLvx",
    "block": "{\"symbols\":[\"transitionClass\",\"@isActive\",\"@isTransparent\",\"@isHeimatRedesign\",\"@onClick\"],\"statements\":[[5,\"transition-class-provider\",[],[[\"@duration\",\"@in\",\"@isMountable\"],[200,[23,2,[]],true]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[7,\"div\",false],[12,\"class\",[28,\"local-class\",[[28,\"join\",[\" \",[28,\"array\",[\"backdrop\",[23,1,[]],[28,\"if\",[[23,4,[]],\"heimat\"],null],[28,\"if\",[[23,3,[]],\"transparent\"],null]],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/backdrop/styles\"]]]],[3,\"lock-scroll\"],[3,\"on\",[\"click\",[28,\"optional\",[[23,5,[]]],null]]],[8],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/backdrop/template.hbs"
    }
  });

  _exports.default = _default;
});