define("@clark/cms-ui/components/common/flyout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tkorK/Bm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[14,1,[[28,\"hash\",null,[[\"trigger\",\"Menu\"],[[23,0,[\"trigger\"]],[28,\"component\",[\"common/flyout/menu\"],[[\"menu\"],[[23,0,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/flyout/template.hbs"
    }
  });

  _exports.default = _default;
});