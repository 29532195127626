define("@clark/cms-ui/components/next-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DKQ/aXSk",
    "block": "{\"symbols\":[\"@items\"],\"statements\":[[7,\"section\",true],[11,\"class\",[29,[[28,\"local-class\",[\"next-steps\"],[[\"from\"],[\"@clark/cms-ui/components/next-steps/styles\"]]]]]],[10,\"data-test-next-steps\",\"\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"layout-container\"],[[\"from\"],[\"@clark/cms-ui/components/next-steps/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"h2\",true],[11,\"class\",[29,[[28,\"local-class\",[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/next-steps/styles\"]]]]]],[10,\"data-test-next-steps-title\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"next-steps.title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[5,\"numbered-list\",[],[[\"@items\"],[[23,1,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/next-steps/template.hbs"
    }
  });

  _exports.default = _default;
});