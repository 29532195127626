define("ember-is-mobile/initializers/is-mobile-service-injector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * isMobile service injector
   *
   * Supports auto injecting the isMobile service app-wide.
   * Generated by the ember-is-mobile addon.
   */
  function initialize() {
    var application = arguments[1] || arguments[0];
    application.inject('controller', 'isMobile', 'service:isMobile');
    application.inject('component', 'isMobile', 'service:isMobile');
    application.inject('route', 'isMobile', 'service:isMobile');
    application.inject('view', 'isMobile', 'service:isMobile');
  }

  var _default = {
    name: 'isMobile',
    initialize: initialize
  };
  _exports.default = _default;
});