define("ember-animated/templates/components/animated-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qsrsv2kP",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[4,\"animated-each\",[[24,[\"items\"]]],[[\"key\",\"rules\",\"use\",\"duration\",\"group\",\"initialInsertion\",\"finalRemoval\"],[[24,[\"key\"]],[24,[\"rules\"]],[24,[\"use\"]],[24,[\"duration\"]],[24,[\"group\"]],[24,[\"initialInsertion\"]],[24,[\"finalRemoval\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-value.hbs"
    }
  });

  _exports.default = _default;
});