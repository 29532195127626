define("@clark/cms-ui/components/skeptics-slider/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "from-desktop": "only screen and (min-width: 1200px)",
    "outer": "_outer_1f05g5",
    "images": "_images_1f05g5",
    "image": "_image_1f05g5",
    "current": "_current_1f05g5",
    "detail": "_detail_1f05g5",
    "quote": "_quote_1f05g5",
    "text": "_text_1f05g5",
    "animate": "_animate_1f05g5",
    "skeptics-slider-text": "_skeptics-slider-text_1f05g5",
    "controls": "_controls_1f05g5",
    "play": "_play_1f05g5",
    "main-slide": "_main-slide_1f05g5"
  };
  _exports.default = _default;
});