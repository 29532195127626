define("@clark/cms-ui/components/numbered-list/item/number-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "waNx+DY3",
    "block": "{\"symbols\":[\"@desktopFluidWidth\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"\\n    number-icon\\n    \",[28,\"if\",[[23,1,[]],\"desktop-fluid-width\"],null],\"\\n  \"],null]],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/number-icon/styles\"]]]]]],[13,2],[12,\"data-test-number-icon\",\"\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"positioning-container\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/number-icon/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"ellipse\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/number-icon/styles\"]]]]]],[10,\"data-test-number-icon-ellipse\",\"\"],[8],[9],[0,\"\\n\\n    \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"number\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/number-icon/styles\"]]]]]],[10,\"data-test-number-icon-number\",\"\"],[8],[0,\"\\n      \"],[1,[23,0,[\"number\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/numbered-list/item/number-icon/template.hbs"
    }
  });

  _exports.default = _default;
});