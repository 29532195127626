define("@clark/cms-ui/components/two-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/C9Y0501",
    "block": "{\"symbols\":[\"section\",\"@footer\",\"@header\",\"@content\"],\"statements\":[[5,\"common/cms-section\",[[12,\"class\",[28,\"grid\",[\"container\"],null]]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[[12,\"class\",\"mb-3\"]],[[\"@title\"],[[23,3,[\"title\"]]]]],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[[12,\"data-test-two-column-content\",\"\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[23,4,[]]],[[\"class\"],[[28,\"concat\",[[28,\"local-class\",[\"content\"],[[\"from\"],[\"@clark/cms-ui/components/two-column/styles\"]]]],null]]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,2,[\"mobile\",\"href\"]]],null,{\"statements\":[[0,\"    \"],[5,\"button\",[],[[\"@isPrimary\",\"@url\"],[[28,\"not\",[[23,2,[\"isSecondary\"]]],null],[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,2,[\"mobile\",\"href\"]],[23,2,[\"desktop\",\"href\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,2,[\"mobile\",\"text\"]],[23,2,[\"desktop\",\"text\"]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/two-column/template.hbs"
    }
  });

  _exports.default = _default;
});