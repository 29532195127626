define("@clark/cms-ui/components/video-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L6EK9yKO",
    "block": "{\"symbols\":[\"@text\",\"@title\",\"@previewImageUrl\",\"@url\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"grid\",[\"row\"],null],\" \",[28,\"local-class\",[\"video-section-root\"],[[\"from\"],[\"@clark/cms-ui/components/video-section/styles\"]]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-12 col-md-8 offset-md-2\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/video-section/styles\"]]]]]],[8],[1,[28,\"html-safe\",[[23,2,[]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"text\"],[[\"from\"],[\"@clark/cms-ui/components/video-section/styles\"]]]]]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-12 col-md-10 offset-md-1\"],null]],[8],[0,\"\\n    \"],[5,\"video-player\",[],[[\"@previewImageUrl\",\"@url\",\"@title\"],[[23,3,[]],[23,4,[]],[23,2,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/video-section/template.hbs"
    }
  });

  _exports.default = _default;
});