define("@clark/cms-ui/components/form-builder/input-field/toggle-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "83amwAi/",
    "block": "{\"symbols\":[\"@isChecked\",\"@isDisabled\",\"&attrs\",\"@onClick\"],\"statements\":[[7,\"label\",true],[11,\"class\",[29,[[28,\"local-class\",[\"slider-input\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/toggle-button/styles\"]]]]]],[8],[0,\"\\n  \"],[5,\"input\",[[12,\"class\",[29,[[28,\"local-class\",[\"slider-input-checkbox\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/toggle-button/styles\"]]]]]],[13,3],[3,\"on\",[\"click\",[28,\"optional\",[[23,4,[]]],null]]]],[[\"@type\",\"@checked\",\"@disabled\"],[\"checkbox\",[23,1,[]],[23,2,[]]]]],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"slider round\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/toggle-button/styles\"]]]]]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/toggle-button/template.hbs"
    }
  });

  _exports.default = _default;
});