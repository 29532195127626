define("@clark/cms-ui/components/backdrop/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "z-index-backdrop": "518",
    "duration-normal": "200ms",
    "heimat-c-white-opaque": "rgba(255,255,255,0.5)",
    "entry-iterations": "1",
    "backdrop-color": "rgba(32, 49, 81, 0.5)",
    "backdrop": "_backdrop_18b4vg",
    "heimat": "_heimat_18b4vg",
    "transparent": "_transparent_18b4vg",
    "enter": "_enter_18b4vg",
    "exit": "_exit_18b4vg",
    "exit-active": "_exit-active_18b4vg",
    "enter-active": "_enter-active_18b4vg"
  };
  _exports.default = _default;
});