define("@clark/cms-ui/components/form-builder/input-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I99chSaB",
    "block": "{\"symbols\":[\"@placeholder\",\"@value\",\"@name\",\"&attrs\",\"@onChange\",\"@fieldType\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"input-field-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,6,[]],\"phone\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"form-builder/input-field/phone\",[[13,4]],[[\"@placeholder\",\"@value\",\"@name\",\"@onChange\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,6,[]],\"email\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"form-builder/input-field/email\",[[12,\"class\",[29,[[28,\"local-class\",[\"form-input\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/styles\"]]]]]],[13,4]],[[\"@placeholder\",\"@value\",\"@name\",\"@onChange\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"input\",false],[12,\"class\",[29,[[28,\"local-class\",[\"form-input\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/styles\"]]]]]],[12,\"placeholder\",[23,1,[]]],[12,\"value\",[23,2,[]]],[12,\"name\",[23,3,[]]],[13,4],[3,\"on\",[\"input\",[23,0,[\"onChange\"]]]],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/template.hbs"
    }
  });

  _exports.default = _default;
});