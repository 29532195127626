define("@clark/cms-ui/components/thumbnail-list/thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xOz0NxiC",
    "block": "{\"symbols\":[\"&attrs\",\"@imgSrc\",\"@title\",\"@description\"],\"statements\":[[1,[28,\"thumbnail/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[7,\"section\",false],[12,\"class\",[29,[[28,\"local-class\",[\"thumbnail-container\"],[[\"from\"],[\"@clark/cms-ui/components/thumbnail-list/thumbnail/styles\"]]]]]],[12,\"data-test-thumbnail-container\",\"\"],[13,1],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"class\",[29,[[28,\"local-class\",[\"thumbnail-image\"],[[\"from\"],[\"@clark/cms-ui/components/thumbnail-list/thumbnail/styles\"]]]]]],[11,\"src\",[23,2,[\"url\"]]],[11,\"alt\",[23,2,[\"altText\"]]],[8],[9],[0,\"\\n  \"],[7,\"h3\",true],[11,\"class\",[29,[[28,\"typo\",[\"h3\"],null],\" \",[28,\"local-class\",[\"thumbnail-title\"],[[\"from\"],[\"@clark/cms-ui/components/thumbnail-list/thumbnail/styles\"]]]]]],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[23,3,[]]],[[\"class\"],[[28,\"markdown\",[\"reset-styles\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[11,\"class\",[28,\"typo\",[\"c2\"],null]],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[23,4,[]]],[[\"class\"],[[28,\"markdown\",[\"reset-styles\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/thumbnail-list/thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});