define("@clark/cms-ui/components/slide-show/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "heimat-c-black": "#0E1621",
    "heimat-brand-primary": "#0439D8",
    "heimat-brand-secondary": "#080F8C",
    "heimat-brand-primary-alt": "#87CDF9",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "gutter-width-mobile": "1.5rem",
    "negative-gutter-width-mobile": "-1.5rem",
    "gutter-width-desktop": "2rem",
    "slide-show": "_slide-show_1mgleh",
    "slide-show-content": "_slide-show-content_1mgleh",
    "text": "_text_1mgleh",
    "title": "_title_1mgleh",
    "fade-in": "_fade-in_1mgleh",
    "fade-in-animation": "_fade-in-animation_1mgleh",
    "cta-section": "_cta-section_1mgleh",
    "cta": "_cta_1mgleh",
    "demo": "_demo_1mgleh",
    "demo-1": "_demo-1_1mgleh",
    "navigation": "_navigation_1mgleh",
    "navigation-item": "_navigation-item_1mgleh _button-reset_17xnva",
    "active": "_active_1mgleh",
    "demo-subject": "_demo-subject_1mgleh",
    "demo-background": "_demo-background_1mgleh",
    "demo-progress-indicator": "_demo-progress-indicator_1mgleh"
  };
  _exports.default = _default;
});