define("@clark/cms-ui/utils/strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleize = titleize;

  function titleize() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return value.split(/(?:^|\s|-|\/)/).map(function (word) {
      return Ember.String.capitalize(word);
    }).join(' ');
  }
});