define("@clark/cms-ui/components/form-builder/input-field/email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8tVQG4S",
    "block": "{\"symbols\":[\"@placeholder\",\"@value\",\"@name\",\"&attrs\"],\"statements\":[[7,\"input\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"if\",[[23,0,[\"isError\"]],\"input-form-error\"],null]],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/email/styles\"]]]]]],[12,\"placeholder\",[23,1,[]]],[12,\"value\",[23,2,[]]],[12,\"name\",[23,3,[]]],[13,4],[12,\"type\",\"email\"],[3,\"on\",[\"input\",[23,0,[\"onChange\"]]]],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"input-form-error-message\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/email/styles\"]]]]]],[10,\"data-test-input-email-error\",\"\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"forms.email.error_message\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/email/template.hbs"
    }
  });

  _exports.default = _default;
});