define("@clark/cms-ui/components/textbox/textbox-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v97/yqYa",
    "block": "{\"symbols\":[\"&attrs\",\"@icon\",\"@title\",\"@description\"],\"statements\":[[7,\"div\",false],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"icon-container\"],[[\"from\"],[\"@clark/cms-ui/components/textbox/textbox-item/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"icon\",[],[[\"@source\",\"@size\"],[[23,2,[]],\"extra-large\"]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"h4\",true],[11,\"class\",[29,[[28,\"typo\",[\"h4\"],null],\" \",[28,\"local-class\",[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/textbox/textbox-item/styles\"]]]]]],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[23,3,[]]],[[\"class\"],[[28,\"markdown\",[\"reset-styles\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[11,\"class\",[28,\"typo\",[\"c2\"],null]],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[23,4,[]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/textbox/textbox-item/template.hbs"
    }
  });

  _exports.default = _default;
});