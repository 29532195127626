define("@clark/cms-ui/components/visually-hidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cg6pR1CN",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"span\",false],[12,\"class\",[29,[[28,\"local-class\",[\"visually-hidden\"],[[\"from\"],[\"@clark/cms-ui/components/visually-hidden/styles\"]]]]]],[12,\"data-test-visually-hidden\",\"\"],[13,1],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/visually-hidden/template.hbs"
    }
  });

  _exports.default = _default;
});