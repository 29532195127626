define("ember-svg-jar/inlined/kv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ico-kv</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M28.648 6.25a18.218 18.218 0 01-15.963 32.007A18.236 18.236 0 1028.648 6.251z\" fill=\"#BFD2F5\"/><path d=\"M30.19 21.46h-3.968v-3.968a.794.794 0 00-.793-.794h-3.175a.794.794 0 00-.794.794v3.968h-3.968a.794.794 0 00-.794.794v3.175c0 .438.356.793.794.793h3.968v3.968c0 .439.356.794.794.794h3.175a.794.794 0 00.793-.794v-3.968h3.968a.794.794 0 00.794-.793v-3.175a.794.794 0 00-.794-.794zm-.793 3.175h-4.762v4.762h-1.587v-4.762h-4.762v-1.587h4.762v-4.762h1.587v4.762h4.762v1.587z\" fill=\"#2B6CDE\"/><path d=\"M23.841 4C12.883 4 4 12.883 4 23.841 4 34.8 12.883 43.683 23.841 43.683c10.958 0 19.842-8.884 19.842-19.842A19.841 19.841 0 0023.84 4zm0 38.095c-10.081 0-18.254-8.172-18.254-18.254 0-10.081 8.173-18.254 18.254-18.254 10.082 0 18.254 8.173 18.254 18.254-.011 10.077-8.177 18.243-18.254 18.254z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});