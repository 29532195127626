define("ember-svg-jar/inlined/unfall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ico-unfall</title><g fill-rule=\"nonzero\" transform=\"translate(4 4)\" fill=\"none\"><path d=\"M36.813 10.59L14.285 33.118a.735.735 0 01-1.04 0L4.458 24.33l-2.655 2.655a.735.735 0 000 1.04l9.854 9.854a.735.735 0 001.04 0L37.88 12.697a.736.736 0 000-1.04l-1.067-1.067z\" fill=\"#BFD2F5\"/><path d=\"M39.002 10.534L29.148.68a2.323 2.323 0 00-3.285 0L.68 25.863a2.323 2.323 0 000 3.285l9.854 9.854a2.323 2.323 0 003.285 0L39.002 13.82a2.323 2.323 0 000-3.285zm-1.122 2.163L12.697 37.88a.735.735 0 01-1.04 0l-9.854-9.854a.735.735 0 010-1.04L26.986 1.803a.735.735 0 011.04 0l9.854 9.854a.736.736 0 010 1.04z\" fill=\"#2B6CDE\"/><circle fill=\"#2B6CDE\" transform=\"rotate(-44.998 19.841 15.352)\" cx=\"19.841\" cy=\"15.352\" r=\"1.587\"/><circle fill=\"#2B6CDE\" transform=\"rotate(-45.002 24.33 19.841)\" cx=\"24.331\" cy=\"19.841\" r=\"1.587\"/><circle fill=\"#2B6CDE\" transform=\"rotate(-45.002 15.352 19.841)\" cx=\"15.352\" cy=\"19.841\" r=\"1.587\"/><circle fill=\"#2B6CDE\" transform=\"rotate(-44.998 19.841 24.33)\" cx=\"19.841\" cy=\"24.331\" r=\"1.587\"/><path d=\"M39.002 25.863l-4.927-4.926-1.095 1.095 4.927 4.927a.775.775 0 010 1.095l-9.854 9.854a.775.775 0 01-1.095 0l-4.927-4.927-1.095 1.096 4.927 4.927a2.323 2.323 0 003.284 0l9.855-9.854a2.322 2.322 0 000-3.287zM5.608 18.746l1.095-1.095-4.928-4.927a.775.775 0 010-1.095l9.854-9.854a.774.774 0 011.096 0l4.927 4.927 1.095-1.095L13.819.68a2.323 2.323 0 00-3.284 0L.68 10.534a2.323 2.323 0 000 3.285l4.928 4.927z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});