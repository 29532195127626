define("@clark/cms-ui/components/form-builder/input-field/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JGqNYdjP",
    "block": "{\"symbols\":[\"@checked\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"input-form-checkbox-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/check-box/styles\"]]]]]],[8],[0,\"\\n  \"],[7,\"label\",true],[11,\"class\",[29,[[28,\"local-class\",[\"input-form-checkbox-container\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/check-box/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"input\",[[12,\"class\",[29,[[28,\"local-class\",[\"input-form-checkbox\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/check-box/styles\"]]]]]],[13,2]],[[\"@type\",\"@checked\"],[\"checkbox\",[23,1,[]]]]],[0,\"\\n    \"],[5,\"icon\",[[12,\"class\",[29,[[28,\"local-class\",[\"input-form-checkmark\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/check-box/styles\"]]]]]]],[[\"@source\"],[\"tick-sign-square\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/check-box/template.hbs"
    }
  });

  _exports.default = _default;
});