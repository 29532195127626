define("@clark/cms-ui/components/trustpilot/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u4wj5l3E",
    "block": "{\"symbols\":[\"@templateId\",\"@height\"],\"statements\":[[7,\"div\",false],[12,\"data-test-trustpilot-widget\",\"\"],[12,\"class\",\"trustpilot-widget\"],[12,\"data-template-id\",[23,1,[]]],[12,\"data-style-height\",[23,2,[]]],[12,\"data-schema-type\",[23,0,[\"trustpilotWidgetConfig\",\"dataSchemaType\"]]],[12,\"data-locale\",[23,0,[\"trustpilotWidgetConfig\",\"locale\"]]],[12,\"data-businessunit-id\",[23,0,[\"trustpilotWidgetConfig\",\"businessunitId\"]]],[12,\"data-style-width\",[23,0,[\"trustpilotWidgetConfig\",\"width\"]]],[12,\"data-theme\",[23,0,[\"trustpilotWidgetConfig\",\"theme\"]]],[12,\"data-tags\",[23,0,[\"trustpilotWidgetConfig\",\"tags\"]]],[12,\"data-stars\",[23,0,[\"trustpilotWidgetConfig\",\"stars\"]]],[3,\"did-insert\",[[23,0,[\"loadTrustpilotWidget\"]]]],[8],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/trustpilot/widget/template.hbs"
    }
  });

  _exports.default = _default;
});