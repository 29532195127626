define("@clark/cms-ui/components/numbered-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LDRxksW4",
    "block": "{\"symbols\":[\"@orientation\",\"&attrs\",\"@number\",\"@text\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"\\n    numbered-list-item\\n    orientation-\",[28,\"or\",[[23,1,[]],\"horizontal\"],null],\"\\n  \"],null]],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/styles\"]]]]]],[13,2],[12,\"data-test-numbered-list-item\",\"\"],[12,\"data-test-numbered-list-item-number-value\",[23,3,[]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"icon\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/styles\"]]]]]],[10,\"data-test-numbered-list-item-icon\",\"\"],[8],[0,\"\\n    \"],[5,\"numbered-list/item/number-icon\",[],[[\"@number\",\"@desktopFluidWidth\"],[[23,3,[]],[28,\"eq\",[[23,1,[]],\"desktop-vertical\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"text\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/styles\"]]]]]],[10,\"data-test-numbered-list-item-text\",\"\"],[8],[0,\"\\n    \"],[1,[23,4,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/numbered-list/item/template.hbs"
    }
  });

  _exports.default = _default;
});