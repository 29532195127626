define("@clark/cms-ui/components/key-listener/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZGDV1VD",
    "block": "{\"symbols\":[\"@handler\",\"@key\"],\"statements\":[[1,[28,\"did-insert\",[[23,0,[\"validate\"]]],null],false],[0,\"\\n\"],[1,[28,\"did-update\",[[23,0,[\"validate\"]],[23,2,[]],[23,1,[]]],null],false],[0,\"\\n\"],[1,[28,\"on-document\",[\"keyup\",[23,0,[\"handleKeyEvent\"]]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/key-listener/template.hbs"
    }
  });

  _exports.default = _default;
});