define("@clark/cms-ui/components/common/cms-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3gMIHcuP",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"section\",false],[12,\"class\",[29,[[28,\"grid\",[\"container\"],null],\" \",[28,\"local-class\",[\"section-container\"],[[\"from\"],[\"@clark/cms-ui/components/common/cms-section/styles\"]]]]]],[13,1],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"header\",\"body\",\"footer\"],[[28,\"component\",[\"common/cms-section/header\"],null],[28,\"component\",[\"common/cms-section/body\"],null],[28,\"component\",[\"common/cms-section/footer\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/cms-section/template.hbs"
    }
  });

  _exports.default = _default;
});