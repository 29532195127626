define("@clark/cms-ui/components/common/accordion/item/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Azy9YVAw",
    "block": "{\"symbols\":[\"@for\",\"&attrs\",\"&default\"],\"statements\":[[7,\"label\",false],[12,\"class\",[29,[[28,\"local-class\",[\"accordion-item-label\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/label/styles\"]]]]]],[12,\"for\",[23,1,[]]],[13,2],[12,\"data-test-accordion-item-label\",\"\"],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\\n  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"accordion-item-label-icon\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/label/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"icon\",[],[[\"@source\"],[\"arrow-down\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/accordion/item/label/template.hbs"
    }
  });

  _exports.default = _default;
});