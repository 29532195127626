define("@clark/cms-ui/components/modal/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BMfuDi9H",
    "block": "{\"symbols\":[\"@describedBy\",\"@description\",\"@labelledBy\",\"@title\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"header\"],[[\"from\"],[\"@clark/cms-ui/components/modal/header/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[7,\"h2\",true],[11,\"id\",[23,3,[]]],[11,\"class\",[28,\"typo\",[\"h2 text-center\"],null]],[8],[0,\"\\n      \"],[1,[23,4,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[11,\"id\",[23,1,[]]],[11,\"class\",[28,\"typo\",[\"c2 text-center\"],null]],[8],[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/header/template.hbs"
    }
  });

  _exports.default = _default;
});