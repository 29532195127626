define("@clark/cms-ui/components/partner-header/nav-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T8nBTlos",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"span\",false],[12,\"class\",[29,[[28,\"local-class\",[\"nav-item\"],[[\"from\"],[\"@clark/cms-ui/components/partner-header/nav-item/styles\"]]]]]],[13,1],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"Menu\"],[[28,\"component\",[\"partner-header/nav-item/dropdown-menu\"],[[\"item\"],[[23,0,[]]]]]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/partner-header/nav-item/template.hbs"
    }
  });

  _exports.default = _default;
});