define("@clark/cms-ui/helpers/typo", ["exports", "@clark/cms-ui/styles/typo"], function (_exports, _typo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typo = typo;
  _exports.default = void 0;

  function typo(parameters) {
    (false && !(parameters.length < 2) && Ember.assert('Typo helper expects format of classes in one string a.k.a "t1 p2"', parameters.length < 2));
    return parameters[0].split(/\s+/g).map(function (c) {
      return _typo.default[c];
    }).filter(Boolean).join(' ');
  }

  var _default = Ember.Helper.helper(typo);

  _exports.default = _default;
});