define("ember-svg-jar/inlined/hausrat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ico-hausrat</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M35.694 16.735V36.54a.795.795 0 01-.794.793h-2.329v1.588h3.969a.795.795 0 00.793-.794V18.286h.053l-1.692-1.55zM15.111 27.81h3.175v3.175h-3.175z\" fill=\"#BFD2F5\"/><path d=\"M31.085 37.333H10.349v.794c0 .437.668.794 1.488.794H34.16v-1.588h-3.074z\" fill=\"#BFD2F5\"/><path d=\"M43.425 21.669L24.377 4.209a.795.795 0 00-1.072 0L4.257 21.668a.794.794 0 001.073 1.17l3.432-3.146v18.434a2.381 2.381 0 002.38 2.38H36.54a2.381 2.381 0 002.38-2.38V19.693l3.432 3.146a.794.794 0 101.073-1.17zM24.635 38.92V27.81h6.35v11.11h-6.35zm12.698-20.635v19.841a.795.795 0 01-.793.794H32.57V27.81c0-.877-.71-1.588-1.587-1.588h-6.35c-.876 0-1.586.71-1.586 1.588v11.11H11.143a.795.795 0 01-.794-.793V18.286h-.052L23.84 5.87l13.545 12.416h-.053z\" fill=\"#2B6CDE\"/><path d=\"M18.286 26.222H15.11c-.877 0-1.587.71-1.587 1.588v3.174c0 .877.71 1.587 1.587 1.587h3.175c.876 0 1.587-.71 1.587-1.587V27.81c0-.877-.71-1.588-1.587-1.588zm0 4.762H15.11V27.81h3.175v3.174z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});