define("@clark/cms-ui/components/modal/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lO2S1QQ9",
    "block": "{\"symbols\":[\"Wrapper\",\"&default\",\"@isSectioned\",\"&attrs\",\"@isLoading\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"body-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/modal/body/styles\"]]]]]],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"body\"],[[\"from\"],[\"@clark/cms-ui/components/modal/body/styles\"]]]]]],[13,4],[8],[0,\"\\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"spinner\"],[[\"from\"],[\"@clark/cms-ui/components/modal/body/styles\"]]]]]],[8],[0,\"\\n        \"],[5,\"spinner\",[],[[\"@size\",\"@accessibilityLabel\"],[\"large\",[28,\"t\",[\"modal.spinner_accessibility_label\"],null]]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"if\",[[23,3,[]],\"modal/section\",\"blank-component\"],null]],null]],null,{\"statements\":[[0,\"        \"],[6,[23,1,[]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[14,2],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/body/template.hbs"
    }
  });

  _exports.default = _default;
});