define("@clark/cms-ui/components/textbox/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "textbox-list": "_textbox-list_rmos3w",
    "textbox-list-item": "_textbox-list-item_rmos3w"
  };
  _exports.default = _default;
});