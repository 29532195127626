define("ember-svg-jar/inlined/phv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ico-phv</title><defs><path id=\"a\" d=\"M0 0h24.24v40H0z\"/></defs><g transform=\"translate(11 4)\" fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M22.448 3.878l-2.182 17.455a1.83 1.83 0 01-1.818 1.575H5.963a1.83 1.83 0 01-1.818-1.575L1.963 3.818c-.182-.97.667-2.06 1.818-2.06h4.97l.727.727-4.182 4.182 3.697 4.425-1.272 4.665 3.152-4.665-2.487-4.425 4.184-4.182-.545-.818h8.726c1.394.091 1.819 1.363 1.697 2.211M20.63 0H3.66C1.478 0-.219 1.938.023 4.122l2.182 17.515c.243 1.818 1.758 3.211 3.638 3.211h3.515c1.03 0 1.817.789 1.817 1.819v10.301c0 .669-.545 1.214-1.212 1.214h-6.06a.932.932 0 00-.91.908c0 .485.425.91.91.91h16.485a.932.932 0 00.908-.91.93.93 0 00-.908-.908h-6.122a1.215 1.215 0 01-1.211-1.214V26.667c0-1.03.786-1.819 1.818-1.819h3.513c1.82 0 3.395-1.393 3.639-3.211l2.181-17.515C24.508 1.938 22.811 0 20.63 0\" fill=\"#2B6CDE\" mask=\"url(#b)\"/><path d=\"M19.24 1.667h1.617c1.394.091 1.819 1.363 1.697 2.211l-2.182 17.455a1.83 1.83 0 01-1.818 1.577h-2.647\" fill=\"#BFD2F5\" mask=\"url(#b)\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});