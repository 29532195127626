define("ember-animated/components/animated-value", ["exports", "ember-animated/templates/components/animated-value"], function (_exports, _animatedValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    A component that animates when a single value changes.
    Animated-value uses the same arguments as animated-each.
    ```hbs
      <AnimatedContainer>
        {{#animated-value counter rules=rules duration=100 as |v|}}
            <span class="numbers">{{v}}</span>
        {{/animated-value}}
      </AnimatedContainer>
  
      <button {{action "increment"}}>+</button>
      <button {{action "decrement"}}>-</button>
    ```
    ```js
    import Component from '@ember/component';
    import { toLeft, toRight } from 'ember-animated/transitions/move-over';
  
    export default Component.extend({
      rules({ oldItems, newItems }) {
        if (oldItems[0] < newItems[0]) {
          return toLeft;
        } else {
          return toRight;
        }
      },
  
      counter: 10,
  
      actions: {
        increment() {
          this.set('counter', this.get('counter') + 1);
        },
        decrement() {
          this.set('counter', this.get('counter') - 1);
        }
      }
    });
    ```
    @class animated-value
    @public
  */
  var _default = Ember.Component.extend({
    tagName: '',
    layout: _animatedValue.default,
    items: Ember.computed('value', function () {
      return [this.get('value')];
    })
  }).reopenClass({
    positionalParams: ['value']
  });

  _exports.default = _default;
});