define("@clark/cms-ui/components/article-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gq34y45s",
    "block": "{\"symbols\":[\"@description\",\"@title\",\"@summaryItems\"],\"statements\":[[7,\"section\",true],[11,\"class\",[29,[[28,\"local-class\",[\"article-summary\"],[[\"from\"],[\"@clark/cms-ui/components/article-summary/styles\"]]]]]],[10,\"data-test-article-summary\",\"\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"layout-container\"],[[\"from\"],[\"@clark/cms-ui/components/article-summary/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"h2\",true],[11,\"class\",[29,[[28,\"local-class\",[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/article-summary/styles\"]]]]]],[10,\"data-test-article-summary-title\",\"\"],[8],[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"description\"],[[\"from\"],[\"@clark/cms-ui/components/article-summary/styles\"]]]]]],[10,\"data-test-article-summary-description\",\"\"],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[5,\"numbered-list\",[],[[\"@items\",\"@layout\"],[[23,3,[]],\"desktop-tiles\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/article-summary/template.hbs"
    }
  });

  _exports.default = _default;
});