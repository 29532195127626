define("@clark/cms-ui/components/video-section/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "heimat-font-serif": "'Tiempos Fine', serif",
    "heimat-font-sans": "'Open Sans', sans-serif",
    "heimat-brand-primary": "#0439D8",
    "heimat-c-black": "#0E1621",
    "video-section-root": "_video-section-root_19qhjn",
    "title": "_title_19qhjn",
    "text": "_text_19qhjn"
  };
  _exports.default = _default;
});