define("@clark/cms-ui/components/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l5/fbWx6",
    "block": "{\"symbols\":[\"@source\",\"@accessibilityLabel\",\"&attrs\"],\"statements\":[[1,[28,\"icon/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[7,\"span\",false],[12,\"class\",[23,0,[\"classes\"]]],[12,\"role\",\"img\"],[12,\"aria-label\",[23,2,[]]],[13,3],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[]],\"placeholder\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"placeholder\"],[[\"from\"],[\"@clark/cms-ui/components/icon/styles\"]]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"svg-jar\",[[23,1,[]]],[[\"focusable\",\"aria-hidden\",\"class\"],[\"false\",\"true\",[28,\"concat\",[[28,\"local-class\",[\"svg\"],[[\"from\"],[\"@clark/cms-ui/components/icon/styles\"]]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/icon/template.hbs"
    }
  });

  _exports.default = _default;
});