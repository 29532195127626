define("@clark/cms-ui/components/stack/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7ADWBdtE",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[23,0,[\"classes\"]]],[13,1],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/stack/item/template.hbs"
    }
  });

  _exports.default = _default;
});