define("@clark/cms-ui/components/article-summary/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "c-white": "#FFFFFF",
    "section-background": "#FFFFFF",
    "max-content-width": "calc(758px + 24px * 2)",
    "title-color": "#01051c",
    "text-color": "#203151",
    "article-summary": "_article-summary_1n88gw",
    "layout-container": "_layout-container_1n88gw",
    "title": "_title_1n88gw",
    "description": "_description_1n88gw"
  };
  _exports.default = _default;
});