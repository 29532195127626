define("ember-svg-jar/inlined/berufsunfahigkeits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<ellipse cx=\"27.31\" cy=\"8.516\" rx=\"5.793\" ry=\"5.419\" fill=\"#BFD2F5\"/><path d=\"M13.363 42.14c5.99 2.53 12.91.43 16.538-5.016.165-.26-3.418-1.511-3.418-4.608v-18.58h1.655v18.58c.276.774 1.324 2.477 3.31 3.097h4.965l-3.31 4.645c-3.202 6.194-13.962 7.488-20.69 4.645C5.688 42.06 2.179 33.611 3.61 26.377c1.432-7.235 7.71-12.441 14.999-12.441.464 0 .84.38.84.85 0 0-.778-.076-1.242-.076-6.62.774-11.676 5.56-12.951 12.002-1.275 6.44 2.118 12.897 8.107 15.427z\" fill=\"#BFD2F5\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M42.457 36.375l5.385 8.22a.96.96 0 11-1.605 1.051l-5.334-8.148A2.843 2.843 0 0038.7 36.48h-7.02a4.805 4.805 0 01-4.8-4.8V15.264a6.72 6.72 0 111.92 0v5.856h9.21a.96.96 0 110 1.92H28.8v8.64a2.883 2.883 0 002.88 2.88h7.02a4.767 4.767 0 013.757 1.815zM27.84 3.84a4.8 4.8 0 100 9.6 4.8 4.8 0 000-9.6z\" fill=\"#2B6CDE\"/><path d=\"M32.317 39.216a15.569 15.569 0 11-12.909-24.271.96.96 0 100-1.92 17.488 17.488 0 1014.5 27.265.96.96 0 10-1.59-1.074z\" fill=\"#2B6CDE\"/>",
    "attrs": {
      "width": "49",
      "height": "49",
      "viewBox": "0 0 49 49",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});