define("@clark/cms-ui/components/form-builder/input-field/phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "laHfQzL6",
    "block": "{\"symbols\":[\"@placeholder\",\"@value\",\"@name\",\"&attrs\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"form-input-phone-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/phone/styles\"]]]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"form-input-phone-icon-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/phone/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"icon\",[[12,\"class\",[29,[[28,\"local-class\",[\"form-input-phone-icon\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/phone/styles\"]]]]]]],[[\"@source\"],[\"de-square\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"input\",false],[12,\"class\",[29,[[28,\"local-class\",[\"form-input\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/phone/styles\"]]]]]],[12,\"placeholder\",[23,1,[]]],[12,\"value\",[23,2,[]]],[12,\"name\",[23,3,[]]],[13,4],[3,\"on\",[\"input\",[23,0,[\"onChange\"]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/phone/template.hbs"
    }
  });

  _exports.default = _default;
});