define("@clark/cms-ui/components/unstyled-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OZRgdBFP",
    "block": "{\"symbols\":[\"@url\",\"@isExternal\",\"@download\",\"&attrs\",\"&default\"],\"statements\":[[7,\"a\",false],[12,\"href\",[23,1,[]]],[12,\"target\",[28,\"if\",[[23,2,[]],\"_blank\"],null]],[12,\"rel\",[28,\"if\",[[23,2,[]],\"noopener noreferrer\"],null]],[12,\"download\",[23,3,[]]],[13,4],[8],[0,\"\\n  \"],[14,5],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/unstyled-link/template.hbs"
    }
  });

  _exports.default = _default;
});