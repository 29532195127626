define("@clark/cms-ui/components/crosslink/crosslink-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "quick-selection-border": "#DADDEC",
    "on-hover-card-box-shadow": "0 3px 10px 0px rgba(0,0,0,0.15)",
    "crosslink-item-height-mobile": "200px",
    "crosslink-item": "_crosslink-item_yklk1f",
    "icon-container": "_icon-container_yklk1f",
    "crosslink-chevron": "_crosslink-chevron_yklk1f"
  };
  _exports.default = _default;
});