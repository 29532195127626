define("@clark/cms-ui/components/modal/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WQM0vcka",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"footer\"],[[\"from\"],[\"@clark/cms-ui/components/modal/footer/styles\"]]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"content\"],[[\"from\"],[\"@clark/cms-ui/components/modal/footer/styles\"]]]]]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/footer/template.hbs"
    }
  });

  _exports.default = _default;
});