define("@clark/cms-ui/components/skeptics-slider/image/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "item": "_item_nvs0mc",
    "bottom": "_bottom_nvs0mc",
    "top": "_top_nvs0mc",
    "animate": "_animate_nvs0mc",
    "image-slide-over": "_image-slide-over_nvs0mc"
  };
  _exports.default = _default;
});