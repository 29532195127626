define("ember-is-mobile/helpers/is-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    isMobile: Ember.inject.service(),
    compute: function compute(params) {
      (false && !(Ember.isPresent(params[0])) && Ember.assert('Missing parameter for the is-mobile helper', Ember.isPresent(params[0])));
      var isMobile = Ember.get(this, 'isMobile');
      return Ember.get(isMobile, params[0]);
    }
  });

  _exports.default = _default;
});