define("@clark/cms-ui/components/crosslink/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HRZNxZuA",
    "block": "{\"symbols\":[\"section\",\"item\",\"&attrs\",\"@header\",\"@items\"],\"statements\":[[5,\"common/cms-section\",[[12,\"class\",[29,[[28,\"local-class\",[\"crosslink-section\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/styles\"]]]]]],[13,3]],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"row justify-content-center\"],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-12 col-lg-8\"],null]],[8],[0,\"\\n      \"],[6,[23,1,[\"header\"]],[],[[\"@title\"],[[23,4,[\"title\"]]]]],[0,\"\\n      \"],[6,[23,1,[\"body\"]],[[12,\"class\",[28,\"grid\",[\"row justify-content-lg-center\"],null]],[12,\"data-test-crosslink\",\"\"]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[11,\"class\",[29,[\"mb-3 mb-md-4 \",[28,\"grid\",[\"col-12 col-md-6\"],null]]]],[8],[0,\"\\n            \"],[5,\"crosslink/crosslink-item\",[[12,\"class\",[29,[[28,\"local-class\",[\"crosslink-item\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/styles\"]]]]]]],[[\"@icon\",\"@title\",\"@content\",\"@link\"],[[23,2,[\"icon\"]],[23,2,[\"title\"]],[23,2,[\"content\"]],[23,2,[\"link\"]]]]],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/crosslink/template.hbs"
    }
  });

  _exports.default = _default;
});