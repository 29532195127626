define("@clark/cms-ui/components/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wo6AdCUA",
    "block": "{\"symbols\":[\"@id\",\"&attrs\",\"&default\",\"@url\",\"@isExternal\",\"@download\",\"@hideExternalIcon\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[5,\"unstyled-link\",[[12,\"class\",[23,0,[\"classes\"]]],[12,\"id\",[23,1,[]]],[13,2],[3,\"on\",[\"click\",[23,0,[\"handleClick\"]]]]],[[\"@url\",\"@isExternal\",\"@download\"],[[23,4,[]],[23,5,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n    \"],[14,3],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[23,5,[]],[28,\"not\",[[23,7,[]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"icon-lockup\"],[[\"from\"],[\"@clark/cms-ui/components/link/styles\"]]]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"icon-layout\"],[[\"from\"],[\"@clark/cms-ui/components/link/styles\"]]]]]],[8],[0,\"\\n          \"],[5,\"icon\",[],[[\"@accessibilityLabel\",\"@source\",\"@size\"],[[28,\"t\",[\"common.new_window_accessibility_label\"],null],\"external-link\",\"small\"]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[23,0,[\"classes\"]]],[12,\"id\",[23,1,[]]],[13,2],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"handleClick\"]]]],[8],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/link/template.hbs"
    }
  });

  _exports.default = _default;
});