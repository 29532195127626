define("@clark/cms-ui/components/button/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K37uhOUX",
    "block": "{\"symbols\":[\"&default\",\"@icon\",\"@isLoading\",\"@isPrimary\"],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"content\"],[[\"from\"],[\"@clark/cms-ui/components/button/content/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"spinner\"],[[\"from\"],[\"@clark/cms-ui/components/button/content/styles\"]]]]]],[8],[0,\"\\n      \"],[5,\"spinner\",[],[[\"@color\",\"@accessibilityLabel\"],[[28,\"if\",[[23,4,[]],\"white\",\"blue\"],null],[28,\"t\",[\"button.spinner_accessibility_label\"],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"local-class\",[\"icon\"],[[\"from\"],[\"@clark/cms-ui/components/button/content/styles\"]]]]]],[8],[0,\"\\n      \"],[5,\"icon\",[],[[\"@source\"],[[28,\"if\",[[23,3,[]],\"placeholder\",[23,2,[]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/button/content/template.hbs"
    }
  });

  _exports.default = _default;
});