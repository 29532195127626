define("ember-svg-jar/inlined/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Service-3</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#203151\" width=\"48\" height=\"48\" rx=\"24\"/><path d=\"M24 12a8.16 8.16 0 00-8.16 8.16c0 4.778 4.784 12.38 7.026 15.28a1.434 1.434 0 002.27.002C27.38 32.55 32.16 24.967 32.16 20.16A8.16 8.16 0 0024 12zm0 10.56a3.36 3.36 0 110-6.72 3.36 3.36 0 010 6.72z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});