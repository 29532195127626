define("@clark/cms-ui/components/common/accordion/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gzWpSVEo",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[0,\"\\n\"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"accordion\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/styles\"]]]]]],[13,1],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"Item\"],[[28,\"component\",[\"common/accordion/item\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/accordion/template.hbs"
    }
  });

  _exports.default = _default;
});