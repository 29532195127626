define("@clark/cms-ui/components/hero/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "breakpoint-medium": "769px",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "image-height-mobile": "384px",
    "container-height": "720px",
    "hero-container": "_hero-container_dnormc",
    "hero-image-container": "_hero-image-container_dnormc",
    "hero-image-mobile": "_hero-image-mobile_dnormc",
    "hero-image-desktop": "_hero-image-desktop_dnormc",
    "hero-content": "_hero-content_dnormc",
    "hero-image": "_hero-image_dnormc",
    "hero-title": "_hero-title_dnormc",
    "hero-description": "_hero-description_dnormc"
  };
  _exports.default = _default;
});