define("@clark/cms-ui/components/common/divider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+zrwiFFd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hr\",true],[11,\"class\",[29,[[28,\"local-class\",[\"divider\"],[[\"from\"],[\"@clark/cms-ui/components/common/divider/styles\"]]]]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/divider/template.hbs"
    }
  });

  _exports.default = _default;
});