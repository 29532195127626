define("@clark/cms-ui/components/common/app-store/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tCiqgxX6",
    "block": "{\"symbols\":[\"@appStoreLink\",\"@playStoreLink\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"app-store-container\"],[[\"from\"],[\"@clark/cms-ui/components/common/app-store/styles\"]]]]]],[8],[0,\"\\n  \"],[5,\"link\",[[12,\"aria-label\",\"Link to Apple App Store (opens in a new window)\"],[12,\"data-test-app-store-ios\",\"\"]],[[\"@url\",\"@isExternal\",\"@hideExternalIcon\"],[[23,1,[]],true,true]],{\"statements\":[[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/assets/images/appstore.jpg\"],[10,\"alt\",\"\"],[10,\"role\",\"presentation\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link\",[[12,\"aria-label\",\"Link to Android Play Store (opens in a new window)\"],[12,\"data-test-app-store-android\",\"\"]],[[\"@url\",\"@isExternal\",\"@hideExternalIcon\"],[[23,2,[]],true,true]],{\"statements\":[[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/assets/images/playstore.jpg\"],[10,\"alt\",\"\"],[10,\"role\",\"presentation\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/app-store/template.hbs"
    }
  });

  _exports.default = _default;
});