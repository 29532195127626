define("@clark/cms-ui/components/faq/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "faq-border": "#DADDEC",
    "from-medium": "only screen and (min-width: 769px)",
    "faq-list": "_faq-list_1ormg8",
    "faq-list-item": "_faq-list-item_1ormg8"
  };
  _exports.default = _default;
});