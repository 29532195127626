define("@clark/cms-ui/components/success-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g10chkFV",
    "block": "{\"symbols\":[\"@imgSrc\",\"@title\",\"@description\",\"@button\"],\"statements\":[[1,[28,\"success-message/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"role\",\"presentation\"],[11,\"class\",[28,\"grid\",[\"row justify-content-center\"],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"grid\",[\"col-12 col-md-8\"],null],\" \",[28,\"local-class\",[\"success-message-container\"],[[\"from\"],[\"@clark/cms-ui/components/success-message/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"class\",[29,[[28,\"local-class\",[\"success-message-image\"],[[\"from\"],[\"@clark/cms-ui/components/success-message/styles\"]]]]]],[11,\"src\",[23,1,[\"url\"]]],[11,\"alt\",[23,1,[\"altText\"]]],[10,\"data-test-success-message-image\",\"\"],[8],[9],[0,\"\\n    \"],[7,\"h2\",true],[11,\"class\",[29,[[28,\"typo\",[\"h2\"],null],\" \",[28,\"local-class\",[\"success-message-header\"],[[\"from\"],[\"@clark/cms-ui/components/success-message/styles\"]]]]]],[10,\"data-test-success-message-header\",\"\"],[8],[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"typo\",[\"c2\"],null],\" \",[28,\"local-class\",[\"success-message-description\"],[[\"from\"],[\"@clark/cms-ui/components/success-message/styles\"]]]]]],[10,\"data-test-success-message-description\",\"\"],[8],[0,\"\\n      \"],[1,[23,3,[]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"button\",[[12,\"data-test-success-message-button\",\"\"]],[[\"@url\"],[[23,4,[\"href\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,4,[\"text\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/success-message/template.hbs"
    }
  });

  _exports.default = _default;
});