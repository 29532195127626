define("@clark/cms-ui/components/numbered-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "obAer1Fj",
    "block": "{\"symbols\":[\"item\",\"index\",\"@layout\",\"&attrs\",\"@items\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"\\n    numbered-list\\n    layout-\",[28,\"or\",[[23,3,[]],\"rows\"],null],\"\\n  \"],null]],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/styles\"]]]]]],[13,4],[12,\"data-test-numbered-list\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"item-container\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/styles\"]]]]]],[10,\"data-test-numbered-list-item-container\",\"\"],[8],[0,\"\\n      \"],[5,\"numbered-list/item\",[],[[\"@number\",\"@text\",\"@orientation\"],[[28,\"add\",[[23,2,[]],1],null],[23,1,[\"text\"]],[28,\"if\",[[28,\"eq\",[[23,3,[]],\"desktop-tiles\"],null],\"desktop-vertical\"],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/numbered-list/template.hbs"
    }
  });

  _exports.default = _default;
});