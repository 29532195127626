define("@clark/cms-ui/components/cms-footer/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fAQt6W+a",
    "block": "{\"symbols\":[\"&attrs\",\"@url\",\"@isExternal\",\"&default\"],\"statements\":[[7,\"li\",false],[12,\"class\",[29,[[28,\"local-class\",[\"link-item\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/link/styles\"]]]]]],[13,1],[8],[0,\"\\n  \"],[5,\"link\",[[12,\"class\",[29,[[28,\"local-class\",[\"link\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/link/styles\"]]]]]]],[[\"@url\",\"@isSubdued\",\"@isExternal\",\"@hideExternalIcon\"],[[23,2,[]],true,[23,3,[]],true]],{\"statements\":[[0,\"\\n    \"],[14,4],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/cms-footer/link/template.hbs"
    }
  });

  _exports.default = _default;
});