define("@clark/cms-ui/components/common/accordion/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b4OVkkSi",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"accordion-item\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/styles\"]]]]]],[13,1],[8],[0,\"\\n  \"],[7,\"input\",true],[11,\"class\",[29,[[28,\"local-class\",[\"accordion-item-checkbox\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/styles\"]]]]]],[11,\"id\",[23,0,[\"id\"]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\\n  \"],[14,2,[[28,\"hash\",null,[[\"Label\",\"Content\"],[[28,\"component\",[\"common/accordion/item/label\"],[[\"for\"],[[23,0,[\"id\"]]]]],[28,\"component\",[\"common/accordion/item/content\"],null]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/accordion/item/template.hbs"
    }
  });

  _exports.default = _default;
});