define("@clark/cms-ui/components/transition-class-provider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gme1XeTy",
    "block": "{\"symbols\":[\"&default\",\"@in\"],\"statements\":[[1,[28,\"did-update\",[[23,0,[\"update\"]],[23,2,[]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not-eq\",[[23,0,[\"status\"]],\"unmounted\"],null]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,0,[\"transitionClass\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/transition-class-provider/template.hbs"
    }
  });

  _exports.default = _default;
});