define("@clark/cms-ui/components/hero-small/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "image-container": "_image-container_2r86iu",
    "hero-small-description": "_hero-small-description_2r86iu"
  };
  _exports.default = _default;
});