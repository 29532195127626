define("@clark/cms-ui/components/carousel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "c-white": "#FFFFFF",
    "carousel-btn-border": "#DADDEC",
    "border-radius-normal": "0.25rem",
    "img-active-md-width": "368px",
    "img-active-md-height": "276px",
    "img-active-width": "290px",
    "img-active-height": "218px",
    "heimat-image-active-width": "62.125rem",
    "heimat-image-width": "37.5rem",
    "heimat-image-height": "35rem",
    "heimat-slider-width": "187.5rem",
    "img-width": "230px",
    "img-height": "170px",
    "slider-list-height": "img-md-height",
    "slider-control-content-width": "200px",
    "slider-list": "_slider-list_lznw4",
    "slider-list-item": "_slider-list-item_lznw4",
    "slider-img": "_slider-img_lznw4",
    "slider-control": "_slider-control_lznw4",
    "slider-control-left": "_slider-control-left_lznw4",
    "slider-control-right": "_slider-control-right_lznw4",
    "heimat-slider-control": "_heimat-slider-control_lznw4",
    "slider-control-content": "_slider-control-content_lznw4",
    "slider-video": "_slider-video_lznw4",
    "heimat-slider-video": "_heimat-slider-video_lznw4",
    "heimat-carousel-section": "_heimat-carousel-section_lznw4",
    "heimat-container": "_heimat-container_lznw4",
    "heimat-slider-control-content": "_heimat-slider-control-content_lznw4",
    "heimat-slider-control-header": "_heimat-slider-control-header_lznw4",
    "heimat-slider-control-header-subtitle": "_heimat-slider-control-header-subtitle_lznw4",
    "heimat-slider-control-buttons": "_heimat-slider-control-buttons_lznw4",
    "heimat-prev-button": "_heimat-prev-button_lznw4",
    "heimat-next-button": "_heimat-next-button_lznw4"
  };
  _exports.default = _default;
});