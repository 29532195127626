define("@clark/cms-ui/helpers/grid", ["exports", "@clark/cms-ui/styles/grid"], function (_exports, _grid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.grid = grid;
  _exports.default = void 0;

  function grid(parameters) {
    if (!parameters[0]) {
      return;
    }

    (false && !(parameters.length < 2) && Ember.assert('Grid helper expects format of classes in one string a.k.a "col-6"', parameters.length < 2));
    return parameters[0].split(/\s+/g).map(function (c) {
      return _grid.default[c];
    }).filter(Boolean).join(' ');
  }

  var _default = Ember.Helper.helper(grid);

  _exports.default = _default;
});