define("@clark/cms-ui/components/form-builder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w6DCpeq0",
    "block": "{\"symbols\":[\"field\",\"@form\",\"&attrs\",\"@inputFields\"],\"statements\":[[1,[28,\"form-builder/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[7,\"form\",false],[12,\"class\",[28,\"grid\",[\"row\"],null]],[13,3],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-12 col-md-6\"],null]],[8],[0,\"\\n      \"],[5,\"form-builder/input-field\",[[12,\"data-test-input-field\",[23,1,[\"name\"]]]],[[\"@label\",\"@name\",\"@value\",\"@placeholder\",\"@fieldType\",\"@onChange\"],[[23,1,[\"label\"]],[23,1,[\"name\"]],[28,\"get\",[[28,\"get\",[[23,2,[]],[23,1,[\"name\"]]],null],\"value\"],null],[23,1,[\"placeholder\"]],[23,1,[\"fieldType\"]],[28,\"fn\",[[23,0,[\"updateValue\"]],[23,1,[\"name\"]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/template.hbs"
    }
  });

  _exports.default = _default;
});