define("@clark/cms-ui/modifiers/dropdown-menu", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var MOUSE_LOCS_TRACKED = 3; // number of past mouse locations to track

  var DELAY = 300; // ms delay when user appears to be entering submenu

  var DEFAULTS = {
    rowSelector: 'li',
    submenuSelector: '*',
    submenuDirection: 'below',
    tolerance: 100,
    // bigger = more forgivey when entering submenu
    enter: function enter() {},
    exit: function exit() {},
    activate: function activate() {},
    deactivate: function deactivate() {}
  }; // eslint-disable-next-line ember/new-module-imports

  var _default = (0, _emberModifier.modifier)(function (element, positional) {
    var _positional = _slicedToArray(positional, 1),
        options_ = _positional[0];

    var options = Object.assign(DEFAULTS, options_);
    var menu = element;
    var menuRows = menu.querySelectorAll(options.rowSelector);
    var activeRow = null;
    var mouseLocs = [];
    var lastDelayLoc = null;
    var timeoutId = null;
    /**
     * Return the amount of time that should be used as a delay before the
     * currently hovered row is activated.
     *
     * Returns 0 if the activation should happen immediately. Otherwise,
     * returns the number of milliseconds that should be delayed before
     * checking again to see if the row should be activated.
     */

    var activationDelay = function activationDelay() {
      if (!activeRow) {
        // If there is no other submenu row already active, then
        // go ahead and activate immediately.
        return 0;
      }

      var offsetMenu = activeRow.getBoundingClientRect();
      var offsetSubMenu = activeRow.querySelector(options.submenuSelector).getBoundingClientRect();
      var upperLeft = {
        x: offsetSubMenu.left,
        y: offsetMenu.top - options.tolerance
      };
      var upperRight = {
        x: offsetSubMenu.left + offsetSubMenu.width,
        y: upperLeft.y
      };
      var lowerLeft = {
        x: offsetSubMenu.left,
        y: offsetMenu.top + offsetSubMenu.height + options.tolerance
      };
      var lowerRight = {
        x: offsetSubMenu.left + offsetSubMenu.width,
        y: lowerLeft.y
      };
      var loc = mouseLocs[mouseLocs.length - 1];
      var previousLoc = mouseLocs[0];

      if (!loc) {
        return 0;
      }

      if (!previousLoc) {
        previousLoc = loc;
      }

      if (previousLoc.x < offsetSubMenu.left || previousLoc.x > lowerRight.x || previousLoc.y < offsetMenu.top || previousLoc.y > lowerRight.y) {
        // If the previous mouse location was outside of the entire
        // menu's bounds, immediately activate.
        return 0;
      }

      if (lastDelayLoc && loc.x === lastDelayLoc.x && loc.y === lastDelayLoc.y) {
        // If the mouse hasn't moved since the last time we checked
        // for activation status, immediately activate.
        return 0;
      } // Detect if the user is moving towards the currently activated
      // submenu.
      //
      // If the mouse is heading relatively clearly towards
      // the submenu's content, we should wait and give the user more
      // time before activating a new row. If the mouse is heading
      // elsewhere, we can immediately activate a new row.
      //
      // We detect this by calculating the slope formed between the
      // current mouse location and the upper/lower right points of
      // the menu. We do the same for the previous mouse location.
      // If the current mouse location's slopes are
      // increasing/decreasing appropriately compared to the
      // previous's, we know the user is moving toward the submenu.
      //
      // Note that since the y-axis increases as the cursor moves
      // down the screen, we are looking for the slope between the
      // cursor and the upper right corner to decrease over time, not
      // increase (somewhat counterintuitively).


      function slope(a, b) {
        return (b.y - a.y) / (b.x - a.x);
      }

      var decreasingCorner = upperRight;
      var increasingCorner = lowerRight; // Our expectations for decreasing or increasing slope values
      // depends on which direction the submenu opens relative to the
      // main menu. By default, if the menu opens on the right, we
      // expect the slope between the cursor and the upper right
      // corner to decrease over time, as explained above. If the
      // submenu opens in a different direction, we change our slope
      // expectations.

      if (options.submenuDirection === 'left') {
        decreasingCorner = lowerLeft;
        increasingCorner = upperLeft;
      } else if (options.submenuDirection === 'below') {
        decreasingCorner = lowerRight;
        increasingCorner = lowerLeft;
      } else if (options.submenuDirection === 'above') {
        decreasingCorner = upperLeft;
        increasingCorner = upperRight;
      }

      var decreasingSlope = slope(loc, decreasingCorner);
      var increasingSlope = slope(loc, increasingCorner);
      var previousDecreasingSlope = slope(previousLoc, decreasingCorner);
      var previousIncreasingSlope = slope(previousLoc, increasingCorner);

      if (decreasingSlope < previousDecreasingSlope || increasingSlope > previousIncreasingSlope) {
        // Mouse is moving from previous location towards the
        // currently activated submenu. Delay before activating a
        // new menu row, because user may be moving into submenu.
        lastDelayLoc = loc;
        return DELAY;
      }

      lastDelayLoc = null;
      return 0;
    };
    /**
     * Activate a menu row.
     */


    var activate = function activate(row) {
      if (row === activeRow) {
        return;
      }

      if (activeRow) {
        options.deactivate(activeRow);
      }

      options.activate(row);
      activeRow = row;
    };
    /**
     * Possibly activate a menu row. If mouse movement indicates that we
     * shouldn't activate yet because user may be trying to enter
     * a submenu's content, then delay and check again later.
     */


    var possiblyActivate = function possiblyActivate(row) {
      var delay = activationDelay();

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (delay) {
        timeoutId = setTimeout(function () {
          possiblyActivate(row);
        }, delay);
      } else {
        activate(row);
      }
    };
    /**
     * Keep track of the last few locations of the mouse.
     */


    var mousemoveDocument = function mousemoveDocument(event) {
      // this value becoming negative when user scrolls down to page.
      var scrollFromTop = document.body.getBoundingClientRect().y;
      mouseLocs.push({
        x: event.pageX,
        y: event.pageY + scrollFromTop
      });

      if (mouseLocs.length > MOUSE_LOCS_TRACKED) {
        mouseLocs.shift();
      }
    };
    /**
     * Cancel possible row activations when leaving the menu entirely
     */


    var mouseleaveMenu = function mouseleaveMenu() {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (activeRow) {
        options.deactivate(activeRow);
      }

      activeRow = null;
    };
    /**
     * Trigger a possible row activation whenever entering a new row.
     */


    var mouseenterRow = function mouseenterRow() {
      if (timeoutId) {
        // Cancel any previous activation delays
        clearTimeout(timeoutId);
      }

      options.enter(this);
      possiblyActivate(this);
    };
    /**
     * Immediately activate a row if the user clicks on it.
     */


    var clickRow = function clickRow() {
      activate(this);
    };
    /**
     * Deactivate menu when user out of it
     */


    var mouseleaveRow = function mouseleaveRow() {
      var delay = activationDelay(); // if delay returns higher than 0 then
      // user is intented to move cursor to the menu.

      if (!delay) {
        options.deactivate(activeRow);
        activeRow = null;
      }
    };
    /**
     * Hook up initial menu events
     */


    menu.addEventListener('mouseleave', mouseleaveMenu, false);
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/NodeList#Example
     * However, some older browsers have not implemented NodeList.forEach()
     * */

    Array.prototype.forEach.call(menuRows, function (menuRow) {
      menuRow.addEventListener('mouseenter', mouseenterRow, false);
      menuRow.addEventListener('mouseleave', mouseleaveRow, false);
      menuRow.addEventListener('click', clickRow, false);
    });
    window.addEventListener('mousemove', mousemoveDocument, false);
    return function () {};
  });

  _exports.default = _default;
});