define("@clark/cms-ui/components/faq/faq-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6ehuosbJ",
    "block": "{\"symbols\":[\"&attrs\",\"@title\",\"@description\"],\"statements\":[[7,\"div\",false],[13,1],[8],[0,\"\\n  \"],[7,\"h4\",true],[11,\"class\",[28,\"typo\",[\"h4\"],null]],[10,\"data-test-faq-title\",\"\"],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[23,2,[]]],[[\"class\"],[[28,\"markdown\",[\"reset-styles\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[11,\"class\",[28,\"typo\",[\"c2\"],null]],[10,\"data-test-faq-description\",\"\"],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[23,3,[]]],[[\"class\"],[[28,\"markdown\",[\"reset-styles\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/faq/faq-item/template.hbs"
    }
  });

  _exports.default = _default;
});