define("@clark/cms-ui/components/numbered-list/item/number-icon/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "number-color": "#01051c",
    "ellipse-color": "#0439d7",
    "number-icon": "_number-icon_g0rix",
    "positioning-container": "_positioning-container_g0rix",
    "number": "_number_g0rix",
    "ellipse": "_ellipse_g0rix",
    "desktop-fluid-width": "_desktop-fluid-width_g0rix"
  };
  _exports.default = _default;
});