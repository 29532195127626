define("@clark/cms-ui/components/trustpilot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "plfaz1cr",
    "block": "{\"symbols\":[\"section\",\"&attrs\",\"@header\",\"@tag\"],\"statements\":[[7,\"section\",true],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[\"trustpilot \",[28,\"local-class\",[\"trustpilot-container\"],[[\"from\"],[\"@clark/cms-ui/components/trustpilot/styles\"]]]]]],[12,\"data-test-trustpilot\",\"\"],[13,2],[8],[0,\"\\n\\n    \"],[5,\"common/cms-section\",[[13,2]],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[\"header\"]],[[12,\"class\",[29,[[28,\"typo\",[\"text-center\"],null],\" \",[28,\"grid\",[\"offset-md-2 col-md-8\"],null]]]]],[[\"@title\",\"@description\"],[[23,3,[\"title\"]],[23,3,[\"description\"]]]]],[0,\"\\n\\n      \"],[6,[23,1,[\"body\"]],[[12,\"class\",[28,\"grid\",[\"row\"],null]]],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"grid\",[\"col-12\"],null],\" \",[28,\"local-class\",[\"widget-container\"],[[\"from\"],[\"@clark/cms-ui/components/trustpilot/styles\"]]]]]],[8],[0,\"\\n          \"],[5,\"trustpilot/widget\",[],[[\"@height\",\"@templateId\",\"@tag\"],[[23,0,[\"trustpilotWidgetConfig\",\"height\"]],[23,0,[\"trustpilotWidgetConfig\",\"templateId\"]],[23,4,[]]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/trustpilot/template.hbs"
    }
  });

  _exports.default = _default;
});